import {
  FormControl,
  Modal,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  TextField,
  NativeSelect,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMarketsContext } from "../../../../utils/contexts/MarketsProvider";
import { useProductsContext } from "../../../../utils/contexts/ProductsProvider";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import moment from "moment";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function MarketsPricesFormModals({ row, open, handleClose, formAction }) {
  const classes = useStyles();
  const { user } = useAuthenticationContext();
  const { listMarkets, createMarketPrice, updateMarketPrice } =
    useMarketsContext();
  const { listVarieties } = useProductsContext();
  const formik = useFormik({
    initialValues: {
      id: row.id === undefined ? "" : row.id,
      varietyId: row.variete_id === undefined ? "" : row.variete_id,
      marketId: row.marche_id === undefined ? "" : row.marche_id,
      prixDetaillant:
        row.prix_detaillant === undefined ? "" : row.prix_detaillant,
      prixDemiGros: row.prix_demi_gros === undefined ? "" : row.prix_demi_gros,
      sacDemiGros: row.sac_demi_gros === undefined ? "" : row.sac_demi_gros,
      prixGros: row.prix_gros === undefined ? "" : row.prix_gros,
      quantiteStockee:
        row.quantite_stockee === undefined ? "" : row.quantite_stockee,
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object().shape(
      {
        id: Yup.string(),
        varietyId: Yup.number()
          .typeError("Doit être un nombre")
          .required("Champ requis"),
        marketId: Yup.number()
          .typeError("Doit être un nombre")
          .required("Champ requis"),
        prixDetaillant: Yup.string().required("Champ requis").nullable(true),
        prixDemiGros: Yup.string().nullable(true),
        prixGros: Yup.string().nullable(true),
        sacDemiGros: Yup.string()
          .notRequired()
          .when("prixDemiGros", {
            is: (val) => val === undefined,
            then: Yup.string().notRequired().nullable(true),
            otherwise: Yup.string()
              .oneOf(["sac_10_kg", "sac_25_kg", "sac_50_kg", "sac_100_kg"])
              .required("Champ requis!"),
          }),
        quantiteStockee: Yup.number()
          .typeError("Doit être un nombre")
          .notRequired()
          .positive("Entrer un nombre positif!")
          .nullable(true),
      },
      [["prixDemiGros", "sacDemiGros"]]
    ),

    onSubmit: async (values, onSubmitProps) => {
      let data = {};
      onSubmitProps.setSubmitting(true);
      data = {
        variete_id: JSON.parse(values.varietyId),
        marche_id: JSON.parse(values.marketId),
        prix_detaillant: values.prixDetaillant,
        prix_demi_gros: values.prixDemiGros === "" ? null : values.prixDemiGros,
        sac_demi_gros: values.sacDemiGros === "" ? null : values.sacDemiGros,
        prix_gros: values.prixGros === "" ? null : values.prixGros,
        quantite_stockee:
          values.quantiteStockee === ""
            ? null
            : JSON.parse(values.quantiteStockee),
      };
      if (formAction === "update") {
        updateMarketPrice(row.id, data);
      } else if (formAction === "add") {
        createMarketPrice(data);
      }
      handleClose();
      onSubmitProps.resetForm();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add" ? "NOUVEAU PRIX MARCHE" : "MODIFIER PRIX MARCHE"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <NativeSelect
            error={formik.errors.varietyId ? true : false}
            id="varietyId"
            value={formik.values.varietyId}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir une variete:
            </option>
            {listVarieties.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.varietyId ? true : false}>
            {formik.errors.varietyId}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            error={formik.errors.marketId ? true : false}
            id="marketId"
            value={formik.values.marketId}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir un marche:
            </option>
            {listMarkets.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.marketId ? true : false}>
            {formik.errors.marketId}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixDetaillant ? true : false}
            id="prixDetaillant"
            type="text"
            variant="outlined"
            label="PRIX-DETAILLANT"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixDetaillant}
            size="small"
          />
          <FormHelperText error={formik.errors.prixDetaillant ? true : false}>
            {formik.errors.prixDetaillant}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixDemiGros ? true : false}
            id="prixDemiGros"
            type="text"
            variant="outlined"
            label="PRIX-DEMI-GROS"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixDemiGros}
            size="small"
          />
          <FormHelperText error={formik.errors.prixDemiGros ? true : false}>
            {formik.errors.prixDemiGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            error={formik.errors.sacDemiGros ? true : false}
            id="sacDemiGros"
            value={formik.values.sacDemiGros}
            onChange={formik.handleChange}
          >
            <option value="">Choisir sac demis-gros:</option>
            <option value="sac_10_kg">10 KG</option>
            <option value="sac_25_kg">25 KG</option>
            <option value="sac_50_kg">50 KG</option>
            <option value="sac_100_kg">100 KG</option>
          </NativeSelect>
          <FormHelperText error={formik.errors.sacDemiGros ? true : false}>
            {formik.errors.sacDemiGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixGros ? true : false}
            id="prixGros"
            type="text"
            variant="outlined"
            label="PRIX-GROS"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixGros}
            size="small"
          />
          <FormHelperText error={formik.errors.prixGros ? true : false}>
            {formik.errors.prixGros}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.quantiteStockee ? true : false}
            id="quantiteStockee"
            type="text"
            variant="outlined"
            label="QUANTITE STOCKEE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.quantiteStockee}
            size="small"
          />
          <FormHelperText error={formik.errors.quantiteStockee ? true : false}>
            {formik.errors.quantiteStockee}
          </FormHelperText>
        </FormControl>
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button color="primary" type="submit">
            Enregistrer
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default MarketsPricesFormModals;
