import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useStyles from "./styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@mui/material";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../../../../ui/Modals/ConfirmationModal/ConfirmationModal";
import PropTypes from "prop-types";

/** Component Description:
 *  Component use to render edit, delete and more infos icons
 *  for each table row.
 */

function EditButtonsGroup({
  row,
  rows,
  setRows,
  FormModal,
  handleDeleteRow,
  setFormAction,
  formAction,
  enableMoreDetailsIcon,
  disableEditIcon,
  disableDeleteIcon,
  formModalInitialRow,
  moreDetailsPath,
}) {
  const classes = useStyles();
  // Modal open state
  const [open, setOpen] = React.useState(false);

  // Confirmation modal open state
  const [openConfirmation, setOpenConfirmation] = React.useState(false);

  // Handle modal opening state
  const handleOpen = () => {
    setOpen(true);
    setFormAction("update");
  };

  // Handle modal closing state
  const handleClose = () => {
    setOpen(false);
  };

  const history = useHistory();

  return (
    <React.Fragment>
      <FormModal
        row={row}
        rows={rows}
        open={open}
        handleClose={handleClose}
        setRows={setRows}
        formAction={formAction}
        formModalInitialRow={formModalInitialRow}
      />
      <ConfirmationModal
        open={openConfirmation}
        setOpen={setOpenConfirmation}
        handleYesAction={handleDeleteRow}
        row={row}
      />
      <div className={classes.iconsGroup}>
        {disableEditIcon ? null : (
          <React.Fragment>
            <Tooltip title="Modifier">
              <EditIcon className={classes.editIcon} onClick={handleOpen} />
            </Tooltip>
          </React.Fragment>
        )}

        {disableDeleteIcon ? null : (
          <React.Fragment>
            <Tooltip title="Supprimer">
              <DeleteIcon
                className={classes.deleteIcon}
                onClick={async () => {
                  setOpenConfirmation(true);
                }}
              />
            </Tooltip>
          </React.Fragment>
        )}

        {enableMoreDetailsIcon && (
          <Tooltip title="Plus de details">
            <VisibilityIcon
              className={classes.visibilityIcon}
              onClick={() => history.push(moreDetailsPath)}
            />
          </Tooltip>
        )}
      </div>
    </React.Fragment>
  );
}

EditButtonsGroup.propTypes = {
  /** Table row */
  row: PropTypes.object,
  /** Table rows */
  rows: PropTypes.array,
  /** Function to set table rows */
  setRows: PropTypes.func.isRequired,
  /** Form modal React component */
  FormModal: PropTypes.func,
  /** Handle delete of a row by its id */
  handleDeleteRow: PropTypes.func,
  setFormAction: PropTypes.func,
  formAction: PropTypes.string,
  enableMoreDetailsIcon: PropTypes.bool,
  disableEditIcon: PropTypes.bool,
  disableDeleteIcon: PropTypes.bool,
  /** Path to more details container of the specific table container */
  moreDetailsPath: PropTypes.string,
};

export default EditButtonsGroup;
