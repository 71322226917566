import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_MARKETS_DATA_URL,
  LIST_PARCS_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useMarketsContext } from "../../../utils/contexts/MarketsProvider";
import { useParcsContext } from "../../../utils/contexts/ParcsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import ParcsListTableContainer from "./CustomTable/ParcsListTableContainer";
import MarketsListTableContainer from "./CustomTable/ParcsListTableContainer";

function ParcsListContainer() {
  const { token } = useAuthenticationContext();
  const { setListParcs } = useParcsContext();
  const { setIsListParcsUpdated, isListParcsUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllParcs = async () => {
        try {
          const result = await apiInstance.get(LIST_PARCS_DATA_URL);
          setListParcs(result.data);
          setProgressState(false);
        } catch (err) {
          console.log(err);
          setProgressState(false);
        }
      };

      fetchAllParcs();
    }
  }, [token, setListParcs, setProgressState]);

  React.useEffect(() => {
    if (isListParcsUpdated) {
      const fetchAllParcs = async () => {
        try {
          const result = await apiInstance.get(LIST_MARKETS_DATA_URL);
          setListParcs(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          console.log(err);
        }
      };

      fetchAllParcs();
      setIsListParcsUpdated(false);
    }
  }, [isListParcsUpdated, setIsListParcsUpdated, setListParcs, token]);

  return (
    <CommonPagesLayout>
      <h1>Listes Parcs</h1>
      {progressState ? <CircularProgress /> : <ParcsListTableContainer />}
    </CommonPagesLayout>
  );
}

export default ParcsListContainer;
