import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import useCommonStyles from "../styles";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";

function NavUsers() {
  const classes = useStyles();
  const commonNavItemsClasses = useCommonStyles();
  const { user } = useAuthenticationContext();
  return (
    <div className={classes.navUsers}>
      <Link
        className={`text-white ${commonNavItemsClasses.link}`}
        to="/utilisateurs"
      >
        <SupervisorAccountIcon
          color="inherit"
          className={commonNavItemsClasses.icon}
          fontSize="large"
        />
        Utilisateurs
      </Link>
    </div>
  );
}

export default NavUsers;
