import { FormControl, FormHelperText, NativeSelect } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Items from "./Items/Items";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
}));

function SelectDepartments({ items, formik }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <NativeSelect
            id="departement"
            onChange={formik.handleChange}
            disabled={formik.values.region === "" ? true : false}
            value={formik.values.departement}
          >
            <Items
              items={items}
              compareInput={formik.values.region}
              inputValue={formik.values.departement}
            />
          </NativeSelect>
          <FormHelperText error={formik.errors.departement ? true : false}>
            {formik.errors.departement}
          </FormHelperText>
        </div>
      </FormControl>
    </React.Fragment>
  );
}

export default SelectDepartments;
