import React from "react";
import "./Dashboard.css";
import moment from "moment";
import frLocale from "moment/locale/fr";
import LeafletMap from "../../components/Map/LeafletMap";
import CustomDoughnut from "../../components/Charts/CustomDoughnut";
import CommonPagesLayout from "../../components/layouts/Common/CommonPagesLayout";
import CustomTwoAxisLinesGraph from "../../components/Charts/CustomLinesGraph";
import SelectGroupForDashboard from "../../ui/SelectInput/SelectGroupForDashboard/SelectGroupForDashboard";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import BarChartIcon from "@mui/icons-material/BarChart";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import PieChartIcon from "@mui/icons-material/PieChart";
import { useDashboardContext } from "../../utils/contexts/DashboardProvider";
import CustomBar from "../../components/Charts/CustomBar";
import CustomPie from "../../components/Charts/CustomPie";
import { useOrganizationsContext } from "../../utils/contexts/OrganizationsProvider";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";
import { useProductsContext } from "../../utils/contexts/ProductsProvider";
import apiInstance from "../../utils/api/axios.config";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_COLLECT_POINTS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
  LIST_PRODUCTORS_DATA_URL,
  LIST_PRODUCTS_DATA_URL,
} from "../../utils/api/apiUrls";
import randomColor from "random-color";
import useStyles from "./styles";
import { CircularProgress, Paper, Typography } from "@mui/material";
import AdminGenDasboardContainer from "./AdminGenAndOthersDasboardContainer";
import AdminGenAndOthersDasboardContainer from "./AdminGenAndOthersDasboardContainer";
import ARMDasboardContainer from "./ARMDasboardContainer";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import GlobalDashboardContainer from "./GlobalDashboardContainer";

const DashboardContainer = React.memo(() => {
  const classes = useStyles();

  return (
    <CommonPagesLayout>
      <h1>Dashboard</h1>
      <div className={classes.content}>
        <Typography>Encours de construction ...</Typography>
      </div>
    </CommonPagesLayout>
  );
});

export default DashboardContainer;
