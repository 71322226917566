import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import NatureIcon from "@mui/icons-material/Nature";
import useStyles from "./styles"
import useCommonStyles from "../styles";

function NavProducts() {
   const classes = useStyles()
  const commonNavItemsClasses = useCommonStyles() 
  return (
    <div className={classes.navProducts}>
      <NatureIcon
        color="inherit"
        className={commonNavItemsClasses.icon}
        fontSize="large"
      />
      <NavDropdown
        title={<span className="text-white">Produits</span>}
        id="basic-nav-dropdown"
        className={commonNavItemsClasses.navDropDown}
      >
        <NavDropdown.Item as="div">
          <Link to="/produits" className={commonNavItemsClasses.link}>
            Produits
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link to="/varietes" className={commonNavItemsClasses.link}>
            Variétés
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

export default NavProducts;
