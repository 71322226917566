import React from "react";
import moment from "moment";

const DashboardContext = React.createContext();

export function useDashboardContext() {
  return React.useContext(DashboardContext);
}

function DashboardProvider({ children }) {
  moment.locale("fr");
  const [doghnutFilteredValues, setDoghnutFilteredValues] = React.useState([
    [],
    [],
  ]);
  const [courbeFilteredValue, setCourbeFilteredValue] = React.useState([
    [],
    [],
  ]);

  const [mapMarkers, setMapMarkers] = React.useState({
    centre: {
      lat: 14.5403495,
      lng: -14.8868104,
    },
    makers: [],
  });

  const [selectedProduct, setSelectedProduct] = React.useState("OIGNON");
  const [selectedCollectPoint, setSelectedCollectPoint] =
    React.useState("null");
  const [selectedAgregator, setSelectedAgregator] = React.useState("null");
  const [selectedGroupment, setSelectedGroupment] = React.useState("null");
  const [dateDebutFin, setDateDebutFin] = React.useState([
    moment(),
    moment().add("days", 7),
  ]);

  const [infosStockMap, setInfosStockMap] = React.useState([]);

  // React.useEffect(() => {
  //   if (isLogin && token !== null) {
  //     const fetchListAll = async () => {
  //       try {
  //         const result = await apiInstance.post(LIST_DASHBOARD_INPUT, null, {
  //           headers: { Authorization: `Token ${token}` },
  //         });
  //         setListProducts(result.data.produits);
  //         setListCollectPoints(result.data.points_collectes);
  //         setListAgregators(result.data.agregateurs);
  //         return result;
  //       } catch (err) {
  //         return console.log(err);
  //       }
  //     };
  //     fetchListAll();
  //     if (isListGroupmentsUpdated) {
  //       setIsListGroupmentsUpdated(false);
  //     }
  //     if (isListCollectPointsUpdated) {
  //       setIsListCollectPointsUpdated(false);
  //     }
  //     if (isListAgregatorsUpdated) {
  //       setIsListAgregatorsUpdated(false);
  //     }
  //     if (isListProductsUpdated) {
  //       setIsListProductsUpdated(false);
  //     }
  //   }
  // }, [
  //   token,
  //   isLogin,
  // ]);

  return (
    <DashboardContext.Provider
      value={{
        doghnutFilteredValues,
        setDoghnutFilteredValues,
        courbeFilteredValue,
        setCourbeFilteredValue,
        mapMarkers,
        setMapMarkers,
        selectedProduct,
        setSelectedProduct,
        selectedCollectPoint,
        setSelectedCollectPoint,
        selectedAgregator,
        setSelectedAgregator,
        selectedGroupment,
        setSelectedGroupment,
        setDateDebutFin,
        dateDebutFin,
        infosStockMap,
        setInfosStockMap,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}

export default DashboardProvider;
