import React from "react";
import useStyles from "./styles";
import Logo from "../../assets/logoSysStock-white.svg";

/**Component Descripton:
 * This component is used to render the footer in the UI layout
 */

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <h4>© 2021 - powered by</h4>
      <img
        src={Logo}
        alt="SysStock 3"
        style={{ width: "50px", objectFit: "cover" }}
      />
    </div>
  );
}

export default Footer;
