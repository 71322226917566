import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  CircularProgress,
  Divider,
  List,
  Typography,
} from "@mui/material";
import React from "react";

import { useParams } from "react-router-dom";
import { LIST_SORTIE_DOMMAGES_DATA_URL } from "../../../../utils/api/apiUrls";
import apiInstance from "../../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommonPagesLayout from "../../../../components/layouts/Common/CommonPagesLayout";

function DetailsSortieDommagesContainer() {
  const [detailsSortieDommage, setDetailsSortieDommage] = React.useState("");
  const { token } = useAuthenticationContext();
  let { id } = useParams();

  React.useEffect(() => {
    if (token !== "") {
      const fetchdetailsSortieDommage = async () => {
        try {
          const result = await apiInstance.get(
            LIST_SORTIE_DOMMAGES_DATA_URL + `${id}/`,
            {
              headers: { Authorization: `Token ${token}` },
            }
          );
          setDetailsSortieDommage(result.data);
        } catch (err) {
          console.log(err);
          return;
        }
      };

      fetchdetailsSortieDommage();
    }
  }, [token, id, setDetailsSortieDommage]);

  return (
    <CommonPagesLayout>
      <h1>Details Sortie Dommage</h1>
      {detailsSortieDommage === "" ? (
        <CircularProgress />
      ) : (
        <div
          className="content"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {detailsSortieDommage !== "" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Accordion
                style={{ boxShadow: "4px 4px 4px 2px black", width: "50%" }}
                defaultExpanded
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    {"Référence: " + detailsSortieDommage.reference}
                  </Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <List>
                    <Typography primary="Inbox">
                      {"Type de Mouvement: " + detailsSortieDommage.type_mvt}
                    </Typography>
                    <Typography primary="Inbox">
                      <Typography primary="Inbox">
                        {"Date du Mouvement: " + detailsSortieDommage.date_mvt}
                      </Typography>
                      <Typography primary="Inbox"></Typography>
                      {"Type de Sac: " +
                        (detailsSortieDommage.sac_25_KG && "25 KG") ||
                        (detailsSortieDommage.sac_40_KG && "40 KG")}
                    </Typography>
                    <Typography primary="Inbox">
                      {"Poids pesé: " + detailsSortieDommage.poids_pesee}
                    </Typography>
                    <Typography primary="Inbox">
                      {"Nombre de Sac: " + detailsSortieDommage.nombre_sac}
                    </Typography>
                  </List>
                </AccordionDetails>
                <Divider />
                <List>
                  <Typography variant="h5">INFOS CODE DOMMAGE: </Typography>
                  <Typography primary="Inbox" style={{ paddingLeft: "20px" }}>
                    {"Code: " + detailsSortieDommage.code_dommage.code}
                  </Typography>
                  <Typography primary="Inbox" style={{ paddingLeft: "20px" }}>
                    {"Nom: " + detailsSortieDommage.code_dommage.nom}
                  </Typography>
                  <Typography primary="Inbox" style={{ paddingLeft: "20px" }}>
                    {"Description: " +
                      detailsSortieDommage.code_dommage.description}
                  </Typography>
                </List>
                <Divider />
                <Typography variant="h5">INFOS STOCK: </Typography>
                <AccordionDetails>
                  <List
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "space-evenly",
                    }}
                  >
                    <List>
                      <Typography variant="h6">COXEUR:</Typography>
                      <Divider />
                      <Typography primary="Inbox">
                        {"Nom: " + detailsSortieDommage.stock.coxeur.nom}
                      </Typography>
                      <Typography primary="Inbox">
                        {"Prénom: " + detailsSortieDommage.stock.coxeur.prenom}
                      </Typography>
                      <Typography primary="Inbox">
                        {"Téléphone: " +
                          detailsSortieDommage.stock.coxeur.telephone}
                      </Typography>
                      <Typography primary="Inbox">
                        {"CNI: " + detailsSortieDommage.stock.coxeur.cni}
                      </Typography>
                      <Typography primary="Inbox">
                        {"Point de Collecte: " +
                          detailsSortieDommage.stock.coxeur.point_collecte.nom}
                      </Typography>
                    </List>

                    <List>
                      <Typography variant="h6">VARIETE:</Typography>
                      <Divider />
                      <Avatar
                        alt={detailsSortieDommage.stock.variete.nom}
                        src={detailsSortieDommage.stock.variete.image_base64}
                      />
                      <Typography primary="Inbox">
                        {"Nom: " + detailsSortieDommage.stock.variete.nom}
                      </Typography>
                      <Typography primary="Inbox">
                        {"Description: " +
                          detailsSortieDommage.stock.variete.description}
                      </Typography>
                    </List>
                  </List>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </div>
      )}
    </CommonPagesLayout>
  );
}

export default DetailsSortieDommagesContainer;
