import { TablePagination } from "@mui/material";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import React from "react";

function CustomTablePagination({ ...rest }) {
  return (
    <TablePagination
      labelRowsPerPage="Lignes par pages"
      backIconButtonProps={{ title: "Page précédente" }}
      nextIconButtonProps={{ title: "Page suivante" }}
      rowsPerPageOptions={[5, 10, 25, { label: "Tout", value: -1 }]}
      colSpan={3}
      SelectProps={{
        inputProps: { "aria-label": "Lignes par pages" },
        native: true,
      }}
      ActionsComponent={TablePaginationActions}
      {...rest}
    />
  );
}

export default CustomTablePagination;
