import React from "react";
import { TableCell } from "@mui/material";
import PropTypes from "prop-types";

/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayMarketsListTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.nom}</TableCell>
      <TableCell align="center">{row.superficie}</TableCell>
      <TableCell align="center">{row.frequence}</TableCell>
      <TableCell align="center">
        {row.zone !== null
          ? `${row.zone.region} | ${row.zone.departement} | ${row.zone.commune}`
          : ""}
      </TableCell>
    </React.Fragment>
  );
}
DisplayMarketsListTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayMarketsListTableRows;
