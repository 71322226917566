import React from "react";
import useStyles from "./styles";

function Items({ items, compareInput, inputValue, formik, formAction }) {
  const classes = useStyles();
  const [localCommunes, setLocalCommunes] = React.useState([]);

  React.useEffect(() => {
    // const formikSetCom = (field, value) => {
    //   formik.setFieldValue(`${field}`, value);
    // };

    // if (formAction === "add") {
    //   formikSetCom("commune", "");
    // }
    setLocalCommunes([]);
    items.forEach((r) => {
      return r.departements.forEach((d) => {
        if (d.departement === compareInput) {
          setLocalCommunes(d.communes);
        }
      });
    });
  }, [compareInput, items]);
  return (
    <React.Fragment>
      <option value="" disabled={inputValue === "" ? false : true}>
        Choisir une commune
      </option>

      {localCommunes.length > 0 &&
        localCommunes.map((d, index) => {
          return (
            <option
              value={d.commune}
              key={`${compareInput}-${index}`}
              className={classes.itemText}
            >
              {d.commune}
            </option>
          );
        })}
    </React.Fragment>
  );
}

export default Items;
