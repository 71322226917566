import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    maxWidth: "650px",
    marginBottom: "10px",
    height: "40rem",
    maxHeight: "48rem",
    [theme.breakpoints.down('md')]: {
      height: "36rem",
    },
  },
  media: {
    height: "22rem",
    maxHeight: "24rem",
    [theme.breakpoints.down('md')]: {
      height: "15rem",
    },
  },
  profileContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  listZones: { display: "flex", flexDirection: "column", overflowY: "scroll" },
}));

export default useStyles;
