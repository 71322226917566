import { CircularProgress } from "@mui/material";
import moment from "moment";
import React from "react";
import CustomTable from "../../../../components/Table/CustomTable/CustomTable";
import DisplayMarketsPricesTableRows from "../../../../components/Table/CustomTable/TableContext/MarketsPrices/DisplayMarketsPricesTableRows";
import { exportTableListMarchesPrix } from "../../../../services/filesTransfer";
import MarketsPricesFormModals from "../../../../ui/Modals/FormModals/Markets/MarketsPricesFormModals";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import { useMarketsContext } from "../../../../utils/contexts/MarketsProvider";

function MarketsPricesTableContainer() {
  const { user } = useAuthenticationContext();
  const formModalInitialRow = {
    id: "",
    varietyId: "",
    marketId: "",
    prixDetaillant: "",
    prixDemiGros: "",
    sacDemiGros: "",
    prixGros: "",
    quantiteStockee: "",
  };

  const { listMarketsPrices, deleteMarketPrice } = useMarketsContext();

  const headCells = [
    { label: "Nom du marche", id: "MARCHE_NOM" },
    { label: "Nom de la variete", id: "VARIETE_NOM" },
    { label: "Prix detaillant", id: "PRIX_DETAILLANT" },
    { label: "Prix demis gros", id: "PRIX_DEMIS_GROS" },
    { label: "Sac Demi Gros", id: "SAC_DEMI_GROS" },
    { label: "Prix Gros", id: "PRIX_GROS" },
    { label: "Quantite Stockee", id: "QUANTITE_STOCKEE" },
  ];
  const [, setJSONToSend] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [inputSearchValue, setInputSearchValue] = React.useState("");
  const [formAction, setFormAction] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterValue, setFilterValue] = React.useState(headCells[0].id || "");
  const [filter, setFilter] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (event) => {
    let searchQuery = event.target.value;
    setInputSearchValue(searchQuery);
    setFilter({
      fn: (items) => {
        if (searchQuery === "") return items;
        else
          return items.filter((x) => {
            return (
              x["marche"]["nom"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["variete"]["nom"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              (x.prix_detaillant !== null &&
                x["prix_detaillant"].toLowerCase() === searchQuery) ||
              (x.prix_demi_gros !== null &&
                x["prix_demi_gros"] === searchQuery) ||
              (x["sac_demi_gros"] === "sac_10_kg" &&
                searchQuery.toLowerCase() === "10 kg" &&
                x) ||
              (x["sac_demi_gros"] === "sac_25_kg" &&
                searchQuery.toLowerCase() === "25 kg" &&
                x) ||
              (x["sac_demi_gros"] === "sac_50_kg" &&
                searchQuery.toLowerCase() === "50 kg" &&
                x) ||
              (x["sac_demi_gros"] === "sac_100_kg" &&
                searchQuery.toLowerCase() === "100 kg" &&
                x) ||
              (x.prix_gros !== null &&
                x["prix_gros"].toLowerCase() === searchQuery) ||
              (x.prix_demi_gros_max !== null &&
                x["prix_demi_gros_max"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_gros_min !== null &&
                x["prix_gros_min"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_gros_max !== null &&
                x["prix_gros_max"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_detaillant_min !== null &&
                x["prix_detaillant_min"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_detaillant_max !== null &&
                x["prix_detaillant_max"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_dominant !== null &&
                x["prix_dominant"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              x["date_statut"] === searchQuery ||
              x["date_statut_expiration"] === searchQuery ||
              (searchQuery.toLowerCase() === "oui" && x["statut"] === true) ||
              (searchQuery.toLowerCase() === "non" && x["statut"] === false)
            );
          });
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isASC = orderBy === cellId && order === "asc";
    setOrder(isASC ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = React.useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPaginationSortingAndFiltering = () => {
    return stableSort(filter.fn(rows), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  React.useEffect(() => {
    setRows(listMarketsPrices);
  }, [listMarketsPrices]);

  React.useEffect(() => {
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      setFilteredRows(stabilizedThis.map((el) => el[0]));
    }
    stableSort(filter.fn(rows), getComparator(order, orderBy));
  }, [
    inputSearchValue,
    setFilteredRows,
    getComparator,
    filter,
    rows,
    order,
    orderBy,
  ]);

  React.useEffect(() => {
    if (startDate !== "" && endDate !== "" && listMarketsPrices.length > 0) {
      let tempList = [];
      tempList = listMarketsPrices.filter((ag) => {
        return moment(ag.date_statut).isBetween(startDate, endDate) && ag;
      });
      setRows(tempList);
    } else {
      setRows(listMarketsPrices);
    }
  }, [listMarketsPrices, startDate, endDate]);

  return (
    <React.Fragment>
      <CustomTable
        tableName="marche-prix"
        TableContext={DisplayMarketsPricesTableRows}
        headCells={headCells}
        rows={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        emptyRows={emptyRows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        handleSortRequest={handleSortRequest}
        recordsAfterPaginationSortingAndFiltering={
          recordsAfterPaginationSortingAndFiltering
        }
        filterLabel="Filtrer par:"
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        stableSort={stableSort}
        getComparator={getComparator}
        filter={filter}
        setRows={setRows}
        enableEdit={true}
        formModalInitialRow={formModalInitialRow}
        FormModal={MarketsPricesFormModals}
        handleDeleteRow={deleteMarketPrice}
        formAction={formAction}
        setFormAction={setFormAction}
        disableSelectFilterValue={true}
        disableTemplateButton={true}
        disableLoadButton={true}
        setInputSearchValue={setInputSearchValue}
        filteredRows={filteredRows}
        inputSearchValue={inputSearchValue}
        customExport={exportTableListMarchesPrix}
        setJSONToSend={setJSONToSend}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        enableDateStartEndSearch={true}
        toolTipMessage="Filtrer par date statut"
        disableDeleteIcon={user.is_administrateur ? false : true}
      />
    </React.Fragment>
  );
}

export default MarketsPricesTableContainer;
