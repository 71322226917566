import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_MARKETS_DATA_URL,
  LIST_MARKETS_PRICES_DATA_URL,
  LIST_VARIETIES_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useMarketsContext } from "../../../utils/contexts/MarketsProvider";
import { useProductsContext } from "../../../utils/contexts/ProductsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import MarketsPricesTableContainer from "./CustomTable/MarketsPricesTableContainer";

function MarketsPricesContainer() {
  const { token } = useAuthenticationContext();
  const { setListMarketsPrices, setListMarkets } = useMarketsContext();
  const { setListVarieties } = useProductsContext();
  const { setIsListMarketsPricesUpdated, isListMarketsPricesUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllMarketsPrices = async () => {
        try {
          const result = await apiInstance.get(LIST_MARKETS_PRICES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListMarketsPrices(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          console.log(err);
        }
      };

      const fetchAllMarkets = async () => {
        try {
          const result = await apiInstance.get(LIST_MARKETS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListMarkets(result.data);
        } catch (err) {
          console.log(err);
        }
      };

      const fetchListVarieties = async () => {
        try {
          const result = await apiInstance.get(LIST_VARIETIES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListVarieties(result.data);
        } catch (err) {
          return err;
        }
      };
      fetchAllMarkets();
      fetchListVarieties();
      fetchAllMarketsPrices();
    }
  }, [
    token,
    setListMarketsPrices,
    setListMarkets,
    setListVarieties,
    setProgressState,
  ]);

  React.useEffect(() => {
    if (isListMarketsPricesUpdated) {
      const fetchAllMarketsPrices = async () => {
        try {
          const result = await apiInstance.get(LIST_MARKETS_PRICES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListMarketsPrices(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          console.log(err);
        }
      };

      fetchAllMarketsPrices();
      setIsListMarketsPricesUpdated(false);
    }
  }, [
    token,
    isListMarketsPricesUpdated,
    setIsListMarketsPricesUpdated,
    setListMarketsPrices,
    setProgressState,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Prix Marchés</h1>
      {progressState ? <CircularProgress /> : <MarketsPricesTableContainer />}
    </CommonPagesLayout>
  );
}

export default MarketsPricesContainer;
