import * as React from "react";
import TextField from "@mui/material/TextField";
import MobileDateRangePicker from "@mui/lab/MobileDateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Box from "@mui/material/Box";
import { useDashboardContext } from "../../../utils/contexts/DashboardProvider";
import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
};

function CustomDateRangePicker() {
  const { setDateDebutFin, dateDebutFin } = useDashboardContext();
  console.log(dateDebutFin);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={localeMap["fr"]}>
      <MobileDateRangePicker
        mask={maskMap["fr"]}
        startText="Debut"
        endText="Fin"
        value={dateDebutFin}
        onChange={(newValue) => {
          setDateDebutFin(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <TextField {...startProps} variant="standard" />
            <Box sx={{ mx: 2 }}> au </Box>
            <TextField {...endProps} variant="standard" />
          </React.Fragment>
        )}
      />
    </LocalizationProvider>
  );
}

export default CustomDateRangePicker;
