import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import useStyles from "./styles";
import useCommonStyles from "../styles";

function NavMarkets() {
  const classes = useStyles();
  const commonNavItemsClasses = useCommonStyles();
  return (
    <div className={classes.navMarkets}>
      <ShowChartIcon
        color="inherit"
        className={commonNavItemsClasses.icon}
        fontSize="large"
      />
      <NavDropdown
        title={<span className="text-white">Marchés</span>}
        id="basic-nav-dropdown"
        className={`text-white ${commonNavItemsClasses.navDropDown}`}
      >
        <NavDropdown.Item as="div">
          <Link to="/marches/liste" className={commonNavItemsClasses.link}>
            Marchés
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link to="/marches/prix" className={commonNavItemsClasses.link}>
            Prix Marchés
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

export default NavMarkets;
