import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_PARCS_DATA_URL,
  LIST_PARCS_PRICES_DATA_URL,
  LIST_VARIETIES_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useParcsContext } from "../../../utils/contexts/ParcsProvider";
import { useProductsContext } from "../../../utils/contexts/ProductsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import ParcsPricesTableContainer from "./CustomTable/ParcsPricesTableContainer";

function ParcsPricesContainer() {
  const { token } = useAuthenticationContext();
  const { setListParcsPrices, setListParcs } = useParcsContext();
  const { setListVarieties } = useProductsContext();
  const { setIsListParcsPricesUpdated, isListParcsPricesUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);
  React.useEffect(() => {
    if (token !== "") {
      const fetchAllParcsPrices = async () => {
        try {
          const result = await apiInstance.get(LIST_PARCS_PRICES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListParcsPrices(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          console.log(err);
        }
      };

      const fetchAllParcs = async () => {
        try {
          const result = await apiInstance.get(LIST_PARCS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListParcs(result.data);
        } catch (err) {
          console.log(err);
        }
      };

      const fetchListVarieties = async () => {
        try {
          const result = await apiInstance.get(LIST_VARIETIES_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListVarieties(result.data);
        } catch (err) {
          return err;
        }
      };
      fetchAllParcs();
      fetchListVarieties();
      fetchAllParcsPrices();
    }
  }, [
    token,
    setListParcs,
    setListParcsPrices,
    setListVarieties,
    setProgressState,
  ]);

  React.useEffect(() => {
    if (isListParcsPricesUpdated) {
      const fetchAllMarketsPrices = async () => {
        try {
          const result = await apiInstance.get(LIST_PARCS_PRICES_DATA_URL);
          setListParcsPrices(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          console.log(err);
        }
      };

      fetchAllMarketsPrices();
      setIsListParcsPricesUpdated(false);
    }
  }, [
    token,
    setIsListParcsPricesUpdated,
    setListParcsPrices,
    isListParcsPricesUpdated,
    setProgressState,
  ]);

  return (
    <CommonPagesLayout>
      <h1>Prix Parcs</h1>
      {progressState ? <CircularProgress /> : <ParcsPricesTableContainer />}
    </CommonPagesLayout>
  );
}

export default ParcsPricesContainer;
