import React from "react";
import { TableCell, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayParcsPricesTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.parc.nom}</TableCell>
      <TableCell align="center">{row.variete.nom}</TableCell>
      <TableCell align="center">{row.prix_bascule}</TableCell>
      <TableCell align="center">{row.prix_sac}</TableCell>
      <TableCell align="center">{row.quantite_stockee}</TableCell>
    </React.Fragment>
  );
}
DisplayParcsPricesTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayParcsPricesTableRows;
