import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import AirportShuttleIcon from "@mui/icons-material/AirportShuttle";
import useStyles from "./styles";
import useCommonStyles from "../styles";

function NavParcs() {
  const classes = useStyles();
  const commonNavItemsClasses = useCommonStyles();
  return (
    <div className={classes.navMarkets}>
      <AirportShuttleIcon
        color="inherit"
        className={commonNavItemsClasses.icon}
        fontSize="large"
      />
      <NavDropdown
        title={<span className="text-white">Parcs</span>}
        id="basic-nav-dropdown"
        className={`text-white ${commonNavItemsClasses.navDropDown}`}
      >
        <NavDropdown.Item as="div">
          <Link to="/parcs/liste" className={commonNavItemsClasses.link}>
            Parcs
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link to="/parcs/prix" className={commonNavItemsClasses.link}>
            Prix Parcs
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

export default NavParcs;
