import React from "react";

const UpdatePagesStateContext = React.createContext();

export const useUpdatePagesStateContext = () => {
  return React.useContext(UpdatePagesStateContext);
};

function UpdatePagesStateProvider({ children }) {
  const [isListCollectPointsUpdated, setIsListCollectPointsUpdated] =
    React.useState(false);

  const [isListAgregatorsUpdated, setIsListAgregatorsUpdated] =
    React.useState(false);

  const [isListGroupmentsUpdated, setIsListGroupmentsUpdated] =
    React.useState(false);

  const [isListCoxeursUpdated, setIsListCoxeursUpdated] = React.useState(false);
  const [isListProductorsUpdated, setIsListProductorsUpdated] =
    React.useState(false);
  const [isListCommercantsUpdated, setIsListCommercantsUpdated] =
    React.useState(false);
  const [isListCodesDommagesUpdated, setIsListCodesDommagesUpdated] =
    React.useState(false);
  const [isListEntreeStockUpdated, setIsListEntreeStockUpdated] =
    React.useState(false);
  const [isListStocksUpdated, setIsListStocksUpdated] = React.useState(false);
  const [isListProductsUpdated, setIsListProductsUpdated] =
    React.useState(false);
  const [isListVarietiesUpdated, setIsListVarietiesUpdated] =
    React.useState(false);
  const [isListMarketsUpdated, setIsListMarketsUpdated] = React.useState(false);
  const [isListMarketsPricesUpdated, setIsListMarketsPricesUpdated] =
    React.useState(false);
  const [isUserListUpdated, setIsUserListUpdated] = React.useState(false);
  const [isListParcsUpdated, setIsListParcsUpdated] = React.useState(false);
  const [isListParcsPricesUpdated, setIsListParcsPricesUpdated] =
    React.useState(false);
  return (
    <UpdatePagesStateContext.Provider
      value={{
        isListCollectPointsUpdated,
        setIsListCollectPointsUpdated,
        isListAgregatorsUpdated,
        setIsListAgregatorsUpdated,
        isListGroupmentsUpdated,
        setIsListGroupmentsUpdated,
        isListCoxeursUpdated,
        setIsListCoxeursUpdated,
        isListStocksUpdated,
        setIsListStocksUpdated,
        isListProductsUpdated,
        setIsListProductsUpdated,
        isListVarietiesUpdated,
        setIsListVarietiesUpdated,
        isListMarketsUpdated,
        setIsListMarketsUpdated,
        isListMarketsPricesUpdated,
        setIsListMarketsPricesUpdated,
        isListEntreeStockUpdated,
        setIsListEntreeStockUpdated,
        isListCodesDommagesUpdated,
        setIsListCodesDommagesUpdated,
        isListProductorsUpdated,
        setIsListProductorsUpdated,
        isListCommercantsUpdated,
        setIsListCommercantsUpdated,
        isUserListUpdated,
        setIsUserListUpdated,
        isListParcsUpdated,
        setIsListParcsUpdated,
        isListParcsPricesUpdated,
        setIsListParcsPricesUpdated,
      }}
    >
      {children}
    </UpdatePagesStateContext.Provider>
  );
}

export default UpdatePagesStateProvider;
