import { Container, Typography, Avatar, Paper } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import apiInstance from "../../../../utils/api/axios.config";
import { LIST_USERS_DATA_URL } from "../../../../utils/api/apiUrls";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import defaultUser from "../../../../assets/defaultUser.png";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import LanguageIcon from "@mui/icons-material/Language";
import useStyles from "./style";
import CommonPagesLayout from "../../../../components/layouts/Common/CommonPagesLayout";

function DetailsUserAutresContainer() {
  const [detailsUser, setDetailsUser] = React.useState({
    user: { first_name: "", last_name: "", email: "" },
  });
  const { token } = useAuthenticationContext();
  let { id } = useParams();
  const classes = useStyles();
  React.useEffect(() => {
    const fetchDetailsUser = async () => {
      try {
        const result = await apiInstance.get(LIST_USERS_DATA_URL + `${id}/`, {
          headers: { Authorization: `Token ${token}` },
        });
        setDetailsUser(result.data);
      } catch (err) {
        console.log(err);
        return;
      }
    };
    fetchDetailsUser();
  }, [token, setDetailsUser, id]);

  return (
    <CommonPagesLayout>
      <h1>Détails Utilisateur</h1>

      <Paper className={classes.rootContainer} elevation={3}>
        <div className={classes.rootContainerItems}>
          <div className={classes.item}>
            <Avatar
              alt={`${detailsUser.user?.first_name} ${detailsUser.user?.last_name}`}
              sx={(theme) => ({
                width: "6rem",
                height: "6rem",
                [theme.breakpoints.down("sm")]: {
                  width: "4rem",
                  height: "4rem",
                },
              })}
              src={
                detailsUser.photo_base64 === "" ||
                detailsUser.photo_base64 == null
                  ? defaultUser
                  : detailsUser.photo_base64
              }
            />
            <Typography
              variant="h5"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  fontSize: "18px",
                },
              })}
            >
              {`${detailsUser.user?.first_name} ${detailsUser.user?.last_name}`}
            </Typography>
          </div>
          <div className={classes.item}>
            <PhoneAndroidIcon
              sx={(theme) => ({
                width: "6rem",
                height: "6rem",
                [theme.breakpoints.down("sm")]: {
                  width: "4rem",
                  height: "4rem",
                },
              })}
            />
            <Typography
              variant="h5"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  fontSize: "18px",
                },
              })}
            >
              {detailsUser.user?.username}
            </Typography>
          </div>
          <div className={classes.item}>
            <AlternateEmailIcon
              sx={(theme) => ({
                width: "6rem",
                height: "6rem",
                [theme.breakpoints.down("sm")]: {
                  width: "4rem",
                  height: "4rem",
                },
              })}
            />
            <Typography
              variant="h5"
              sx={(theme) => ({
                width: "fit-content",
                [theme.breakpoints.down("sm")]: {
                  fontSize: "18px",
                },
              })}
            >
              {detailsUser.user?.email}
            </Typography>
          </div>
        </div>
        <div className={classes.rootContainerItems}>
          <div className={classes.item}>
            <VerifiedUserIcon
              alt="role"
              src={defaultUser}
              sx={(theme) => ({
                width: "6rem",
                height: "6rem",
                color: "green",
                [theme.breakpoints.down("sm")]: {
                  width: "4rem",
                  height: "4rem",
                },
              })}
            />
            <Typography
              variant="h5"
              sx={(theme) => ({
                [theme.breakpoints.down("sm")]: {
                  fontSize: "18px",
                },
              })}
            >
              {detailsUser.is_administrateur && "Administrateur"}
              {detailsUser.is_admin_saed && "Administrateur SAED"}
              {detailsUser.is_admin_agregateur && "Administrateur Agrégateur"}
              {detailsUser.is_admin_groupement && "Administrateur Groupement"}
              {detailsUser.is_resp_point_collecte &&
                "Responsable Point Collecte"}
              {detailsUser.is_admin_banque && "Administrateur Banque"}
              {detailsUser.is_payeur_banque && "Payeur Banque"}
              {detailsUser.is_agent_arm && "Agent ARM"}
              {detailsUser.is_commercant && "Commercant"}
              {detailsUser.is_admin_arm && "Admin ARM"}
              {detailsUser.is_agent_marche && "Agent Marché"}
              {detailsUser.is_producteur && "Producteur"}
            </Typography>
          </div>
          {(detailsUser.is_admin_agregateur ||
            detailsUser.is_administrateur ||
            detailsUser.is_admin_saed ||
            detailsUser.is_admin_groupement ||
            detailsUser.is_resp_point_collecte) && (
            <React.Fragment>
              <div className={classes.item}>
                <LanguageIcon
                  alt="organisation"
                  sx={(theme) => ({
                    width: "6rem",
                    height: "6rem",
                    color: "green",
                    [theme.breakpoints.down("sm")]: {
                      width: "4rem",
                      height: "4rem",
                    },
                  })}
                  src={defaultUser}
                />
                <Typography
                  variant="h5"
                  sx={(theme) => ({
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "18px",
                    },
                  })}
                >
                  {(detailsUser.is_resp_point_collecte &&
                    detailsUser.point_collecte?.nom) ||
                    (detailsUser.is_admin_agregateur &&
                      detailsUser.agregateur?.nom) ||
                    (detailsUser.is_admin_groupement &&
                      detailsUser.groupement?.nom) ||
                    (detailsUser.is_administrateur && "Sankofa Impact 2.0") ||
                    (detailsUser.is_admin_saed && "SAED")}
                </Typography>
              </div>
            </React.Fragment>
          )}
          <div className={classes.item}>
            {detailsUser.is_resp_point_collecte && (
              <ul className={classes.listZones}>
                <li>
                  <Typography variant="h7">
                    Téléphone:
                    {detailsUser.point_collecte?.telephone}
                  </Typography>
                </li>

                <li>
                  <Typography variant="h7">
                    Superficie:
                    {detailsUser.point_collecte?.superficie}
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7">
                    Latitude:
                    {detailsUser.point_collecte?.latitude}
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7">
                    Longitude:
                    {detailsUser.point_collecte?.longitude}
                  </Typography>
                </li>
                <li>
                  <Typography variant="h7">
                    Zones:
                    {` ${detailsUser.point_collecte?.zone?.region} | ${detailsUser.point_collecte?.zone?.departement} | ${detailsUser.point_collecte?.zone?.commune}`}
                  </Typography>
                </li>
              </ul>
            )}
            {detailsUser.is_admin_agregateur && (
              <ul className={classes.listZones}>
                <Typography variant="h5">Zones:</Typography>
                {detailsUser.agregateur?.zones?.map((zn) => (
                  <li>{` ${zn.region} | ${zn.departement} | ${zn.commune}`}</li>
                ))}
              </ul>
            )}
            {detailsUser.is_admin_groupement && (
              <ul className={classes.listZones}>
                <li>
                  <Typography variant="h5">
                    Agrégateur: {detailsUser.groupement?.agregateur?.nom}
                  </Typography>
                </li>
              </ul>
            )}
          </div>
        </div>
      </Paper>
    </CommonPagesLayout>
  );
}

export default DetailsUserAutresContainer;
