import React from "react";
import { LIST_ZONES_DATA_URL } from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useAuthenticationContext } from "./AuthenticationProvider";

const ZonesContext = React.createContext(null);

export const useZonesContext = () => {
  return React.useContext(ZonesContext);
};

function ZonesProvider({ children }) {
  const { token } = useAuthenticationContext();
  const [listZones, setListZones] = React.useState([]);
  const [
    listZonesOrderByRegionByDepartmentByCommune,
    setListZonesOrderByRegionByDepartmentByCommune,
  ] = React.useState([]);

  const mountedRef = React.useRef(false);

  const formatListZones = (list) => {
    if (list.length > 0) {
      let tempRegionsArray = [];
      let tempDepartmentsArray = [];
      list.forEach((e) => {
        tempRegionsArray.push(e.region);
        tempDepartmentsArray.push(e.departement);
      });

      let uniqueRegionsArray = [...new Set(tempRegionsArray)];
      let tempDepartmentsByRegionArray = [];
      uniqueRegionsArray.forEach((e) =>
        tempDepartmentsByRegionArray.push({ region: e, departements: [] })
      );
      list.forEach((z) => {
        tempDepartmentsByRegionArray.forEach((d) => {
          if (z.region === d.region) {
            d.departements.push(z.departement);
          }
        });
      });

      tempDepartmentsByRegionArray.forEach((d) => {
        let uniqueDept = [];
        uniqueDept = [...new Set(d.departements)];
        d.departements = [];
        uniqueDept.forEach((uND) => {
          d.departements.push({ departement: uND, communes: [] });
        });
      });

      tempDepartmentsByRegionArray.forEach((r) => {
        r.departements.forEach((d) => {
          list.forEach((z) => {
            if (z.departement === d.departement) {
              d.communes.push({ commune: z.commune });
            }
          });
        });
      });
      setListZonesOrderByRegionByDepartmentByCommune(
        tempDepartmentsByRegionArray
      );
    }
  };

  React.useEffect(() => {
    mountedRef.current = true;
    if (mountedRef.current) {
      if (token !== "") {
        const fetchAllZones = async () => {
          try {
            const result = await apiInstance.get(LIST_ZONES_DATA_URL, {
              headers: { Authorization: `Token ${token}` },
            });
            if (!mountedRef.current) return null;
            formatListZones(result.data);
            setListZones(result.data);
          } catch (err) {
            console.log(err);
          }
        };
        fetchAllZones();
      }
    }

    return () => {
      mountedRef.current = false;
    };
  }, [token, mountedRef]);

  return (
    <ZonesContext.Provider
      value={{ listZonesOrderByRegionByDepartmentByCommune, listZones }}
    >
      {children}
    </ZonesContext.Provider>
  );
}

export default ZonesProvider;
