import React from "react";
import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  Input,
  NativeSelect,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useUsersContext } from "../../../../utils/contexts/UsersProvider";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import convertImgToBase64 from "../../../../utils/helpers";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import FormModalsWrapper from "../FormModalsWrapper";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function UsersFormModal({ row, open, handleClose, formAction }) {
  const classes = useStyles();
  const { createUser, updateUser } = useUsersContext();
  const { user } = useAuthenticationContext();

  const formik = useFormik({
    initialValues: {
      id: row.id === undefined || row.id === null ? "" : row.id,
      avatar:
        row.photo_base64 === null || row.photo_base64 === undefined
          ? ""
          : row.photo_base64,
      nom: row.user ? row.user.last_name : "",
      prenom: row.user ? row.user.first_name : "",
      email: row.user ? row.user.email : "",
      username: row.user ? row.user.username : "",
      password: "",
      passwordConfirmation: "",
      point_collecte: row?.point_collecte?.id,
      role:
        (row.is_administrateur && "is_administrateur") ||
        (row.is_admin_arm && "is_admin_arm") ||
        (row.is_agent_marche_arm && "is_agent_marche_arm") ||
        (row.is_agent_parc_arm && "is_agent_parc_arm") ||
        "",
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object().shape(
      {
        id: Yup.string().default("").notRequired(),
        avatar: Yup.string().notRequired().default(""),
        nom: Yup.string().default(""),
        prenom: Yup.string().default(""),
        password: Yup.string().when("id", {
          is: "",
          then: Yup.string().required("Champ requis!"),
          otherwise: Yup.string().notRequired(),
        }),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Mot de passe different!"
        ),

        email: Yup.string().email("Format incorrect!"),
        username: Yup.number().required("Champ requis!"),
        role: Yup.string().when("id", {
          is: "",
          then: Yup.string().required("Choisir un role!"),
          otherwise: Yup.string().notRequired(),
        }),
      },
      [
        ["password", "id"],
        ["id", "role"],
      ]
    ),

    onSubmit: async (values, onSubmitProps) => {
      let data = {};

      let img =
        values.avatar === ""
          ? null
          : typeof values.avatar === "string"
          ? values.avatar
          : await convertImgToBase64(values.avatar);
      onSubmitProps.setSubmitting(true);
      if (formAction === "add") {
        data = {
          user: {
            username: values.username,
            last_name: values.prenom,
            first_name: values.nom,
            email: values.email === undefined ? "" : values.email,
            password: values.password,
          },
          photo_base64: img,
          is_administrateur: values.role === "is_administrateur" ? true : false,
          is_agent_parc_arm: values.role === "is_agent_parc_arm" ? true : false,
          is_agent_marche_arm:
            values.role === "is_agent_marche_arm" ? true : false,
          is_admin_arm: values.role === "is_admin_arm" ? true : false,
        };

        // createUser(data);
        console.log(data);
      } else if (formAction === "update") {
        data = {
          user: {
            last_name: values.nom,
            first_name: values.prenom,
            email: values.email === undefined ? "" : values.email,
          },
          photo_base64: img,
        };

        // updateUser(row.id, data);
        console.log(data);
      }
      handleClose();
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      title={
        formAction === "add" ? "NOUVEAU UTILISATEUR" : "MODIFIER UTILISATEUR"
      }
      handleClose={handleClose}
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <Button
            variant="contained"
            color={formik.values.avatar === "" ? "secondary" : "primary"}
            component="label"
          >
            Charger une photo
            <Input
              id="avatar"
              type="file"
              hidden
              onChange={(e) => {
                formik.setFieldValue("avatar", e.target.files[0]);
              }}
            />
          </Button>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prenom ? true : false}
            id="prenom"
            type="text"
            variant="outlined"
            label="PRENOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prenom}
            size="small"
          />
          <FormHelperText error={formik.errors.prenom ? true : false}>
            {formik.errors.prenom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.email ? true : false}
            id="email"
            type="text"
            variant="outlined"
            label="EMAIL"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            size="small"
          />
          <FormHelperText error={formik.errors.email ? true : false}>
            {formik.errors.email}
          </FormHelperText>
        </FormControl>

        {formAction === "add" && (
          <React.Fragment>
            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.username ? true : false}
                id="username"
                type="text"
                variant="outlined"
                label="TELEPHONE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.username}
                size="small"
              />
              <FormHelperText error={formik.errors.username ? true : false}>
                {formik.errors.username}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.password ? true : false}
                id="password"
                type="password"
                variant="outlined"
                label="MOT DE PASSE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                size="small"
              />
              <FormHelperText error={formik.errors.password ? true : false}>
                {formik.errors.password}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formRow}>
              <TextField
                error={formik.errors.passwordConfirmation ? true : false}
                id="passwordConfirmation"
                type="password"
                variant="outlined"
                label="CONFIRMER MOT DE PASSE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                size="large"
                style={{ padding: "0 15px" }}
              />
              <FormHelperText
                error={formik.errors.passwordConfirmation ? true : false}
              >
                {formik.errors.passwordConfirmation}
              </FormHelperText>
            </FormControl>
            <FormControl className={classes.formRow}>
              <NativeSelect
                id="role"
                value={formik.values.role}
                onChange={formik.handleChange}
              >
                <option
                  value=""
                  disabled={formAction === "update" ? true : false}
                >
                  Choisir un role
                </option>
                {user.is_administrateur && (
                  <React.Fragment>
                    <option value="is_administrateur">Administrateur</option>
                    <option value="is_admin_arm">Admin ARM</option>
                    <option value="is_agent_parc_arm">Agent Parc ARM</option>
                    <option value="is_agent_marche_arm">
                      Agent Marché ARM
                    </option>
                  </React.Fragment>
                )}

                {user.is_admin_arm && (
                  <React.Fragment>
                    <option value="is_agent_parc_arm">Agent Parc ARM</option>
                    <option value="is_agent_marche_arm">
                      Agent Marché ARM
                    </option>
                  </React.Fragment>
                )}
              </NativeSelect>
              <FormHelperText error={formik.errors.role ? true : false}>
                {formik.errors.role}
              </FormHelperText>
            </FormControl>
          </React.Fragment>
        )}

        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button type="submit" color="primary">
            Enregistrer
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default UsersFormModal;
