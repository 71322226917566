import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  NativeSelect,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function CoxeursFormModal({ row, rows, open, handleClose, formAction }) {
  const classes = useStyles();
  const { listCollectPoints, updateCoxeur, createCoxeur } =
    useOrganizationsContext();

  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      prenom: row.prenom,
      telephone: row.telephone,
      cni: row.cni,
      point_collectName:
        row.point_collecte === undefined ? "" : row.point_collecte.nom,
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      prenom: Yup.string().required("Champ requis!"),
      telephone: Yup.number().required("Champ requis!"),
      cni: Yup.string().notRequired().nullable(true),
      point_collectName: Yup.string().required("Champ requis!"),
    }),

    onSubmit: async (values, onSubmitProps) => {
      onSubmitProps.setSubmitting(true);
      let tempId = "";
      tempId = listCollectPoints.find(
        (e) => e.nom === formik.values.point_collectName
      ).id;

      let data = {};

      if (values.cni === "") {
        data = {
          point_collecte_id: tempId,
          nom: values.nom,
          prenom: values.prenom,
          telephone: values.telephone,
        };
      } else {
        data = {
          point_collecte_id: tempId,
          nom: values.nom,
          prenom: values.prenom,
          telephone: values.telephone,
          cni: values.cni,
        };
      }

      if (formAction === "update") {
        updateCoxeur(row.id, data);
        onSubmitProps.resetForm();
      } else if (formAction === "add") {
        values.id = rows.length + 1;
        createCoxeur(data);
        onSubmitProps.resetForm();
      }
      handleClose();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={formAction === "add" ? "NOUVEAU COXEUR" : "MODIFIER COXEUR"}
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prenom ? true : false}
            id="prenom"
            type="text"
            variant="outlined"
            label="PRENOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prenom}
            size="small"
          />
          <FormHelperText error={formik.errors.prenom ? true : false}>
            {formik.errors.prenom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.telephone ? true : false}
            id="telephone"
            type="text"
            variant="outlined"
            label="TELEPHONE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telephone}
            size="small"
          />
          <FormHelperText error={formik.errors.telephone ? true : false}>
            {formik.errors.telephone}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.cni ? true : false}
            id="cni"
            type="text"
            variant="outlined"
            label="CNI"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cni}
            size="small"
          />
          <FormHelperText error={formik.errors.cni ? true : false}>
            {formik.errors.cni}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="point_collectName"
            value={formik.values.point_collectName}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir un point de collecte
            </option>
            {listCollectPoints.map((c, index) => (
              <option key={index} value={c.nom}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText
            error={formik.errors.point_collectName ? true : false}
          >
            {formik.errors.point_collectName}
          </FormHelperText>
        </FormControl>
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button color="primary" type="submit">
            Enregistrer
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default CoxeursFormModal;
