import makeStyles from '@mui/styles/makeStyles';

const useCommonStyles = makeStyles({
  link: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
    margin: "0 10px 0 10px",
  },
  icon: { marginRight: "5px", color: "white" },
  navDropDown: { fontSize: "0.9rem", color: "white !important" },
});

export default useCommonStyles;
