import React from "react";
import "./Connexion.css";
import Logo from "../../assets/logoSysStock.svg";
import LoginFormContainer from "./LoginForm/LoginFormContainer";
import { useAuthenticationContext } from "../../utils/contexts/AuthenticationProvider";
import LoginPageLayout from "../../components/layouts/Login/LoginPageLayout";
import { useHistory } from "react-router-dom";

/** Component Description:
 *  This component render the connexion page view.
 */
function ConnexionContainer() {
  const { isLogin } = useAuthenticationContext();
  const history = useHistory();

  React.useEffect(() => {
    if (isLogin) {
      history.push("/dashboard");
    }
  }, [isLogin, history]);
  return (
    <LoginPageLayout>
      <div className="connexion">
        {isLogin ? (
          <h1>Vous etes deja connecte!</h1>
        ) : (
          <React.Fragment>
            <div className="connexion-left">
              <LoginFormContainer />
            </div>
            <div className="connexion-right">
              <img
                src={Logo}
                alt="Logo SysStock"
                style={{ width: "30%", objectFit: "cover", opacity: "1" }}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </LoginPageLayout>
  );
}

export default ConnexionContainer;
