import React from "react";
import { Route } from "react-router-dom";
import ConnexionContainer from "../containers/Connexion/ConnexionContainer";
import DashboardContainer from "../containers/Dashboard/DashboardContainer";
import MarketsListContainer from "../containers/Markets/MarketsList/MarketsListContainer";
import MarketsPricesContainer from "../containers/Markets/MarketsPrices/MarketsPricesContainer";
import AgregatorsContainer from "../containers/Organisations/Agregators/AgregatorsContainer";
import GroupmentsContainer from "../containers/Organisations/Groupments/GroupmentsContainer";
import MerchantsContainer from "../containers/Organisations/Commercants/CommercantsContainer";
import ProductorsContainer from "../containers/Organisations/Productors/ProductorsContainer";
import ProductsListContainer from "../containers/Products/ProductsList/ProductsListContainer";
import VarietiesListOfAProductContainer from "../containers/Products/ProductsList/VarietiesListOfAProduct/VarietiesListOfAProductContainer";
import VarietiesListContainer from "../containers/Products/VarietiesList/VarietiesListContainer";
import CodeDommagesContainer from "../containers/Records/CodeDommages/CodeDommagesContainer";
import DashboardProvider from "../utils/contexts/DashboardProvider";
import MarketsProvider from "../utils/contexts/MarketsProvider";
import OrganizationsProvider from "../utils/contexts/OrganizationsProvider";
import ProductsProvider from "../utils/contexts/ProductsProvider";
import RecordsProvider from "../utils/contexts/RecordsProvider";
import UsersProvider from "../utils/contexts/UsersProvider";
import PrivateRouteFirewall from "./PrivateRouteFirewall";
import EntreeStockContainer from "../containers/Records/EntreeStocks/EntreeStockContainer";
import LettreVoituresContainer from "../containers/Records/LettreVoitures/LettreVoituresContainer";
import DetailsLettreVoitureContainer from "../containers/Records/LettreVoitures/DetailsLettreVoiture/DetailsLettreVoitureContainer";
import ControlLettreVoiture from "../containers/Records/LettreVoitures/ControlLettreVoiture/ControlLettreVoiture";
import CollectPointsContainer from "../containers/Organisations/CollectPoints/CollectPointsContainer";
import CoxeursContainer from "../containers/Organisations/Coxeurs/CoxeursContainer";
import SortieDommagesContainer from "../containers/Records/SortieDommages/SortieDommagesContainer";
import ProfileContainer from "../containers/Profile/ProfileContainer";
import DetailsEntreeStockContainer from "../containers/Records/EntreeStocks/DetailsEntreeStock/DetailsEntreeStockContainer";
import DetailsSortieDommagesContainer from "../containers/Records/SortieDommages/DetailsSortieDommages/DetailsSortieDommagesContainer";
import DetailsUserContainer from "../containers/Users/AdminGeneralEtBanque/DetailsUser/DetailsUserContainer";
import UsersContainer from "../containers/Users/AdminGeneralEtBanque/AdminGeneralEtBanque";
import UsersAutresContainer from "../containers/Users/Autres/UsersAutresContainer";
import DetailsUserAutresContainer from "../containers/Users/Autres/DetailsUser/DetailsUserAutresContainer";
import UpdatePagesStateProvider from "../utils/contexts/UpdatePagesStateProvider";
import CombineProvider from "../utils/contexts/CombineProvider";
import AuthenticationProvider from "../utils/contexts/AuthenticationProvider";
import AdminGeneralEtBanque from "../containers/Users/AdminGeneralEtBanque/AdminGeneralEtBanque";
import ZonesProvider from "../utils/contexts/ZonesProvider";
import ParcsProvider from "../utils/contexts/ParcsProvider";
import ParcsListContainer from "../containers/Parcs/ParcsList/ParcsListContainer";
import ParcsPricesContainer from "../containers/Parcs/ParcsPrices/ParcsPricesContainer";

function AllRoutes() {
  return (
    <React.Fragment>
      <CombineProvider
        components={[
          AuthenticationProvider,
          UpdatePagesStateProvider,
          DashboardProvider,
          ProductsProvider,
          MarketsProvider,
          UsersProvider,
          ZonesProvider,
          ParcsProvider,
        ]}
      >
        <Route exact path="/connexion" component={ConnexionContainer} />
        <PrivateRouteFirewall
          exact
          path="/profile"
          component={ProfileContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          key="rootURL"
          exact
          path="/"
          component={DashboardContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />
        <PrivateRouteFirewall
          key="dashboard"
          exact
          path="/dashboard"
          component={DashboardContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/utilisateurs"
          component={UsersAutresContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/utilisateurs/:id"
          component={DetailsUserAutresContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/utilisateurs/admin-general-banque/:id"
          component={DetailsUserContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/produits"
          component={ProductsListContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />
        <PrivateRouteFirewall
          exact
          path="/varietes"
          component={VarietiesListContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/produits/:id"
          component={VarietiesListOfAProductContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/marches/liste"
          component={MarketsListContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/marches/prix"
          component={MarketsPricesContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/parcs/liste"
          component={ParcsListContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />

        <PrivateRouteFirewall
          exact
          path="/parcs/prix"
          component={ParcsPricesContainer}
          allowedRoles={["is_administrateur", "is_admin_arm"]}
        />
      </CombineProvider>
    </React.Fragment>
  );
}

export default AllRoutes;
