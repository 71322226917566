import React from "react";
import { toast } from "react-toastify";
import {
  LIST_PARCS_DATA_URL,
  LIST_PARCS_PRICES_DATA_URL,
} from "../api/apiUrls";
import apiInstance from "../api/axios.config";
import { useAuthenticationContext } from "./AuthenticationProvider";
import { useUpdatePagesStateContext } from "./UpdatePagesStateProvider";

const ParcsContext = React.createContext(null);

export const useParcsContext = () => {
  return React.useContext(ParcsContext);
};

function ParcsProvider({ children }) {
  const [listParcs, setListParcs] = React.useState([]);
  const [listParcsPrices, setListParcsPrices] = React.useState([]);
  const { setIsListParcsUpdated, setIsListParcsPricesUpdated } =
    useUpdatePagesStateContext();

  const createParc = async (data) => {
    try {
      await apiInstance.post(LIST_PARCS_DATA_URL, data);
      setIsListParcsUpdated(true);
      toast.success("Parc crée!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const createParcPrice = async (data) => {
    try {
      await apiInstance.post(LIST_PARCS_PRICES_DATA_URL, data);
      setIsListParcsUpdated(true);
      toast.success("Parc prix crée!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateParc = async (id, data) => {
    try {
      await apiInstance.put(LIST_PARCS_DATA_URL + `${id}/`, data);
      setIsListParcsUpdated(true);
      toast.success("Parc mis à jour!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const updateParcPrice = async (id, data) => {
    try {
      await apiInstance.put(LIST_PARCS_PRICES_DATA_URL + `${id}/`, data);
      setIsListParcsPricesUpdated(true);
      toast.success("Parc prix mis à jour!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteParc = async (row) => {
    try {
      await apiInstance.delete(LIST_PARCS_DATA_URL + `${row.id}/`);
      setIsListParcsPricesUpdated(true);
      toast.success("Parc supprimé!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const deleteParcPrix = async (row) => {
    try {
      await apiInstance.delete(LIST_PARCS_PRICES_DATA_URL + `${row.id}/`);
      setIsListParcsPricesUpdated(true);
      toast.success("Parc prix supprimé!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error(JSON.stringify(err.response.data), {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <ParcsContext.Provider
      value={{
        listParcs,
        setListParcs,
        listParcsPrices,
        setListParcsPrices,
        createParc,
        updateParc,
        deleteParc,
        createParcPrice,
        updateParcPrice,
        deleteParcPrix,
      }}
    >
      {children}
    </ParcsContext.Provider>
  );
}

export default ParcsProvider;
