import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "90%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function CodeDommagesFormModal({ row, rows, open, handleClose, formAction }) {
  const classes = useStyles();
  const { createCodeDommages, updateCodeDommages } = useRecordsContext();

  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      code: row.code,
      description: row.description,
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string().notRequired(),
      nom: Yup.string().required("Champ requis!"),
      code: Yup.string().required("Champ requis!"),
      description: Yup.string().required("Champ requis!"),
    }),

    onSubmit: (values, onSubmitProps) => {
      onSubmitProps.setSubmitting(true);

      if (formAction === "update") {
        updateCodeDommages(row.id, values);
      } else if (formAction === "add") {
        createCodeDommages(values);
      }
      handleClose();
      onSubmitProps.resetForm();
      onSubmitProps.setSubmitting(false);
    },
  });

  React.useEffect(() => {});

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add" ? "NOUVEAU CODE DOMMAGE" : "MODIFIER CODE DOMMAGE"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.code ? true : false}
            id="code"
            type="text"
            variant="outlined"
            label="CODE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.code}
            size="small"
          />
          <FormHelperText error={formik.errors.code ? true : false}>
            {formik.errors.code}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.description ? true : false}
            id="description"
            type="text"
            variant="outlined"
            label="DESCRIPTION"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            size="small"
            multiline
            rows={4}
          />
          <FormHelperText error={formik.errors.description ? true : false}>
            {formik.errors.description}
          </FormHelperText>
        </FormControl>
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button color="primary" type="submit">
            Enregistrer
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default CodeDommagesFormModal;
