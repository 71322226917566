import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "flex",
    width: "95%",
    minHeight: "27rem",
    justifyContent: "space-evenly",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rootContainerItems: {
    flex: 0.5,
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rootMedia: {
    width: "40%",
    marginBottom: "10px",
  },
  media: {
    height: 480,
  },
  item: {
    flex: 0.3,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  avatar: {
    [theme.breakpoints.down("sm")]: {},
  },
  typo: {
    fontSize: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  listZones: { overflowY: "scroll" },
}));

export default useStyles;
