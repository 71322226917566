import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  NativeSelect,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useProductsContext } from "../../../../utils/contexts/ProductsProvider";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down('md')]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    height: "75%",
    justifyContent: "space-evenly",
    alignItems: "center",
    overflowY: "scroll",
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down('md')]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function EntreeStocksFormModal({ row, rows, open, handleClose, formAction }) {
  const classes = useStyles();
  const { listVarieties } = useProductsContext();
  const { listProductors, listCoxeurs, listGroupments } =
    useOrganizationsContext();

  // const parseBeforeSending = (values) => {
  //   let data = {};
  //   if (values.sac_25_KG || values.sac_40_KG) {
  //     data = {
  //       sac_25_KG: JSON.parse(values.sac_25_KG),
  //       sac_40_KG: JSON.parse(values.sac_40_KG),
  //       nombre_sac: JSON.parse(values.nombre_sac),
  //       date_mvt: values.date_mvt,
  //       reference: values.reference,
  //       stock: {
  //         coxeur_id: JSON.parse(values.coxeur),
  //         variete_id: JSON.parse(values.variete),
  //       },
  //       producteur_id: JSON.parse(values.producteur),
  //       groupement_id: JSON.parse(values.groupement),
  //     };
  //   } else {
  //     data = {
  //       date_mvt: values.date_mvt,
  //       reference: values.reference,
  //       poids_pesee: JSON.parse(values.poids_pesee),
  //       stock: {
  //         coxeur_id: JSON.parse(values.coxeur),
  //         variete_id: JSON.parse(values.variete),
  //       },
  //       producteur_id: JSON.parse(values.producteur),
  //       groupement_id: JSON.parse(values.groupement),
  //     };
  //   }
  //   return data;
  // };

  const formik = useFormik(
    {
      initialValues: {
        id: row.id,
        sac_25_KG: row.sac_25_KG,
        sac_40_KG: row.sac_40_KG,
        reference: row.reference,
        date_mvt: row.date_mvt,
        nombre_sac: row.nombre_sac,
        poids_pesee: row.poids_pesee,
        coxeur: row.stock !== undefined ? row.stock.coxeur_id : "",
        variete: row.stock !== undefined ? row.stock.variete_id : "",
        producteur: row.producteur_id,
        groupement: row.groupement_id,
      },
      enableReinitialize: true,
      validateOnChange: false,

      validationSchema: Yup.object().shape(
        {
          id: Yup.string().notRequired(),
          sac_25_KG: Yup.boolean().when("poids_pesee", {
            is: 0,
            then: Yup.boolean().required("Champ requis!"),
            otherwise: Yup.boolean().notRequired(),
          }),
          sac_40_KG: Yup.boolean().when("poids_pesee", {
            is: 0,
            then: Yup.boolean().required("Champ requis!"),
            otherwise: Yup.boolean().notRequired(),
          }),
          reference: Yup.string().required("Champ requis!"),
          date_mvt: Yup.date().required("Champ requis!"),
          nombre_sac: Yup.number().when(["sac_25_KG", "sac_40_KG"], {
            is: (sac_25_KG, sac_40_KG) => sac_40_KG || sac_25_KG,
            then: Yup.number().required("Champ requis!"),
            otherwise: Yup.number().notRequired(),
          }),
          poids_pesee: Yup.number().when(["sac_25_KG", "sac_40_KG"], {
            is: (sac_25_KG, sac_40_KG) => sac_40_KG || sac_25_KG,
            then: Yup.number().notRequired(),
            otherwise: Yup.number().required("Champ requis!"),
          }),
          coxeur: Yup.string().required("Champ requis!"),
          variete: Yup.string().required("Champ requis!"),
          producteur: Yup.string().required("Champ requis!"),
          groupement: Yup.string().required("Champ requis!"),
        },
        [
          ["sac_40_KG", "poids_pesee"],
          ["sac_40_KG", "nombre_sac"],
          ["sac_25_KG", "poids_pesee"],
          ["sac_25_KG", "nombre_sac"],
        ]
      ),

      onSubmit: async (values, onSubmitProps) => {
        onSubmitProps.setSubmitting(true);

        if (formAction === "update") {
          // updateEntreeStocks(values.id, parseBeforeSending(values));
        } else if (formAction === "add") {
          // createEntreeStocks(parseBeforeSending(values));
        }
        handleClose();
        onSubmitProps.resetForm();
        onSubmitProps.setSubmitting(false);
      },
    },
    [[]]
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              autoComplete="off"
              className={classes.form}
            >
              <Typography variant="subtitle2">
                {row.id === ""
                  ? "AJOUTER UNE ENTREE-STOCKS"
                  : "MODIFIER UNE ENTREE-STOCKS"}
              </Typography>

              <FormControl className={classes.formRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.sac_25_KG}
                      onChange={formik.handleChange}
                      name="sac_25_KG"
                      id="sac_25_KG"
                    />
                  }
                  label="Sac 25 KG"
                />
                <FormHelperText error={formik.errors.sac_25_KG ? true : false}>
                  {formik.errors.sac_25_KG}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formRow}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.sac_40_KG}
                      onChange={formik.handleChange}
                      name="sac_40_KG"
                      id="sac_40_KG"
                    />
                  }
                  label="Sac 40 KG"
                />
                <FormHelperText error={formik.errors.sac_40_KG ? true : false}>
                  {formik.errors.sac_40_KG}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formRow}>
                <TextField
                  error={formik.errors.reference ? true : false}
                  id="reference"
                  type="text"
                  variant="outlined"
                  label="REFERENCE"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reference}
                  size="small"
                />
                <FormHelperText error={formik.errors.reference ? true : false}>
                  {formik.errors.reference}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formRow}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  error={formik.errors.date_mvt ? true : false}
                  id="date_mvt"
                  type="date"
                  variant="outlined"
                  label="DATE MOUVEMENT"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date_mvt}
                  size="small"
                />
                <FormHelperText error={formik.errors.date_mvt ? true : false}>
                  {formik.errors.date_mvt}
                </FormHelperText>
              </FormControl>

              {(formik.values.sac_25_KG || formik.values.sac_40_KG) && (
                <FormControl className={classes.formRow}>
                  <TextField
                    error={formik.errors.nombre_sac ? true : false}
                    id="nombre_sac"
                    type="text"
                    variant="outlined"
                    label="NOMBRE SAC"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nombre_sac}
                    size="small"
                  />
                  <FormHelperText
                    error={formik.errors.nombre_sac ? true : false}
                  >
                    {formik.errors.nombre_sac}
                  </FormHelperText>
                </FormControl>
              )}

              {!formik.values.sac_25_KG && !formik.values.sac_40_KG && (
                <FormControl className={classes.formRow}>
                  <TextField
                    error={formik.errors.poids_pesee ? true : false}
                    id="poids_pesee"
                    type="text"
                    variant="outlined"
                    label="POIDS PESEE"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.poids_pesee}
                    size="small"
                  />
                  <FormHelperText
                    error={formik.errors.poids_pesee ? true : false}
                  >
                    {formik.errors.poids_pesee}
                  </FormHelperText>
                </FormControl>
              )}

              <FormControl className={classes.formRow}>
                <NativeSelect
                  id="coxeur"
                  value={formik.values.coxeur}
                  onChange={formik.handleChange}
                >
                  <option
                    value=""
                    disabled={formAction === "update" ? true : false}
                  >
                    Choisir un coxeur
                  </option>
                  {listCoxeurs.map((c, index) => (
                    <option key={index} value={c.id}>
                      {`${c.nom} ${c.prenom}`}
                    </option>
                  ))}
                </NativeSelect>
                <FormHelperText error={formik.errors.coxeur ? true : false}>
                  {formik.errors.coxeur}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formRow}>
                <NativeSelect
                  id="variete"
                  value={formik.values.variete}
                  onChange={formik.handleChange}
                >
                  <option
                    value=""
                    disabled={formAction === "update" ? true : false}
                  >
                    Choisir une variete
                  </option>
                  {listVarieties.map((v, index) => (
                    <option key={index} value={v.id}>
                      {v.nom}
                    </option>
                  ))}
                </NativeSelect>
                <FormHelperText error={formik.errors.variete ? true : false}>
                  {formik.errors.variete}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formRow}>
                <NativeSelect
                  id="producteur"
                  value={formik.values.producteur}
                  onChange={formik.handleChange}
                >
                  <option
                    value=""
                    disabled={formAction === "update" ? true : false}
                  >
                    Choisir un producteur
                  </option>
                  {listProductors.map((p, index) => (
                    <option key={index} value={p.id}>
                      {`${p.nom} ${p.prenom}`}
                    </option>
                  ))}
                </NativeSelect>
                <FormHelperText error={formik.errors.producteur ? true : false}>
                  {formik.errors.producteur}
                </FormHelperText>
              </FormControl>

              <FormControl className={classes.formRow}>
                <NativeSelect
                  id="groupement"
                  value={formik.values.groupement}
                  onChange={formik.handleChange}
                >
                  <option
                    value=""
                    disabled={formAction === "update" ? true : false}
                  >
                    Choisir un groupement
                  </option>
                  {listGroupments.map((g, index) => (
                    <option key={index} value={g.id}>
                      {g.nom}
                    </option>
                  ))}
                </NativeSelect>
                <FormHelperText error={formik.errors.groupement ? true : false}>
                  {formik.errors.groupement}
                </FormHelperText>
              </FormControl>

              <ButtonGroup
                className={classes.formRow}
                variant="contained"
                aria-label="text primary button group"
              >
                <Button type="submit" color="primary">
                  Enregistrer
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    handleClose();
                    formik.resetForm();
                  }}
                >
                  Annuler
                </Button>
              </ButtonGroup>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default EntreeStocksFormModal;
