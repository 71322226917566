import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  NativeSelect,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "70%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function GroupmentsFormModal({
  row,
  rows,
  open,
  handleClose,
  setRows,
  formAction,
}) {
  const classes = useStyles();
  const { listAgregators, createGroupment, updateGroupment } =
    useOrganizationsContext();
  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      agregateur: row.agregateur?.id,
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      agregateur: Yup.string().required("Champ requis!"),
    }),

    onSubmit: (values, onSubmitProps) => {
      let data = { nom: values.nom, agregateur_id: values.agregateur };
      onSubmitProps.setSubmitting(true);
      if (formAction === "add") {
        createGroupment(data);
      } else if (formAction === "update") {
        updateGroupment(values.id, data);
      }
      handleClose();
      onSubmitProps.resetForm();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add" ? "NOUVEAU GROUPEMENT" : "MODIFIER GROUPEMENT"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="NOM"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="agregateur"
            value={formik.values.agregateur}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir un agregateur
            </option>
            {listAgregators.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.agregateur ? true : false}>
            {formik.errors.agregateur}
          </FormHelperText>
        </FormControl>
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button type="submit" color="primary">
            Enregistrer
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default GroupmentsFormModal;
