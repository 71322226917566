import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  navbarWrapper: {
    width: "100%",
    minHeight: "12vh",
    fontFamily: "Roboto, sans-serif",
    zIndex: 999,
  },

  navbar:  {
    background: "#66bb6a linear-gradient(90deg, rgba(109, 204, 114, 1) 17%,rgba(102, 187, 106, 1) 66%,rgba(185, 224, 213, 1) 100%)",
    boxShadow: "0 0 2px 2px gray",
  }
}));

export default useStyles;
