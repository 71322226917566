import makeStyles from "@mui/styles/makeStyles";
import { COLORS } from "../../utils/constants";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    width: "100%",
    minheight: "10vh",
    backgroundColor: COLORS.BACKGROUND_FOOTER,
    color: "white",
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    bottom: "0",
    marginTop: "20px",
  },
});

export default useStyles;
