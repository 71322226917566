import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  navLoginLogout: {
    marginRight: "110px",
    color: "white",
  },
  navLogin: { alignItems: "center", display: "flex", margin: "0 10px 0 10px" },
  userRole: {
    fontStyle: "italic",
  },
});

export default useStyles;
