import React from "react";
import { TableCell, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
/** Component Description:
 *  This component render for each table container,
 *  the row's fields.
 */
function DisplayMarketsPricesTableRows({ row }) {
  return (
    <React.Fragment>
      <TableCell align="left">{row.marche.nom}</TableCell>
      <TableCell align="center">{row.variete.nom}</TableCell>
      <TableCell align="center">
        {row.prix_detaillant === null ? "NEANT" : row.prix_detaillant}
      </TableCell>
      <TableCell align="center">
        {row.prix_demi_gros === null ? "NEANT" : row.prix_demi_gros}
      </TableCell>
      <TableCell align="center">
        {row.sac_demi_gros === "sac_10_kg" && "10 KG"}
        {row.sac_demi_gros === "sac_25_kg" && "25 KG"}
        {row.sac_demi_gros === "sac_50_kg" && "50 KG"}
        {row.sac_demi_gros === "sac_100_kg" && "100 KG"}
        {row.sac_demi_gros === null && "NEANT"}
      </TableCell>
      <TableCell align="center">
        {row.prix_gros === null ? "NEANT" : row.prix_gros}
      </TableCell>
      <TableCell align="center">
        {row.quantite_stockee === null ? "NEANT" : row.quantite_stockee}
      </TableCell>
    </React.Fragment>
  );
}
DisplayMarketsPricesTableRows.propTypes = {
  row: PropTypes.object,
};

export default DisplayMarketsPricesTableRows;
