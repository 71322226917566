import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  buttonsGroup: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
    gap: "15px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  button: {

  },
}));

export default useStyles;
