import React from "react";
import useStyles from "./styles";

function Items({ items, compareInput }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <option
        value=""
        disabled={
          compareInput === "" || compareInput === undefined ? false : true
        }
      >
        Choisir une region
      </option>
      {items.map((item, index) => {
        return (
          <option
            key={item.region}
            value={item.region}
            className={classes.itemText}
          >
            {item.region}
          </option>
        );
      })}
    </React.Fragment>
  );
}

export default Items;
