import {
  FormControl,
  Modal,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  TextField,
  NativeSelect,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMarketsContext } from "../../../../utils/contexts/MarketsProvider";
import { useProductsContext } from "../../../../utils/contexts/ProductsProvider";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import moment from "moment";
import FormModalsWrapper from "../FormModalsWrapper";
import { useParcsContext } from "../../../../utils/contexts/ParcsProvider";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));

function ParcsPricesFormModals({ row, open, handleClose, formAction }) {
  const classes = useStyles();
  const { user } = useAuthenticationContext();
  const { listParcs, createParcPrice, updateParcPrice } = useParcsContext();
  const { listVarieties } = useProductsContext();
  const formik = useFormik({
    initialValues: {
      id: row.id === undefined ? "" : row.id,
      varietyId: row.variete_id === undefined ? "" : row.variete_id,
      parcId: row.parc_id === undefined ? "" : row.parc_id,
      prixBascule: row.prix_bascule === undefined ? "0.0" : row.prix_bascule,
      prixSac: row.prix_sac === undefined ? "0.0" : row.prix_sac,
      quantiteStockee:
        row.quantite_stockee === undefined ? null : row.quantite_stockee,
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      varietyId: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis"),
      parcId: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis"),
      prixBascule: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis")
        .positive("Entrer un nombre positif!")
        .nullable(true),
      prixSac: Yup.number()
        .typeError("Doit être un nombre")
        .required("Champ requis")
        .positive("Entrer un nombre positif!")
        .nullable(true),
      quantiteStockee: Yup.number()
        .typeError("Doit être un nombre")
        .notRequired()
        .positive("Entrer un nombre positif!")
        .nullable(true),
    }),

    onSubmit: async (values, onSubmitProps) => {
      let data = {};
      onSubmitProps.setSubmitting(true);
      values.varietyId = JSON.parse(values.varietyId);
      values.parcId = JSON.parse(values.parcId);
      values.quantiteStockee = JSON.parse(values.quantiteStockee);

      data = {
        variete_id: values.varietyId,
        parc_id: values.parcId,
        prix_bascule: values.prixBascule,
        prix_sac: values.prixSac,
        quantite_stockee: values.quantiteStockee,
      };
      if (formAction === "update") {
        updateParcPrice(row.id, data);
      } else if (formAction === "add") {
        createParcPrice(data);
      }
      handleClose();
      onSubmitProps.resetForm();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={formAction === "add" ? "NOUVEAU PRIX PARC" : "MODIFIER PRIX PARC"}
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <NativeSelect
            id="varietyId"
            value={formik.values.varietyId}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir une variete
            </option>
            {listVarieties.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.varietyId ? true : false}>
            {formik.errors.varietyId}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="parcId"
            value={formik.values.parcId}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir un parc:
            </option>
            {listParcs.map((p, index) => (
              <option key={index} value={p.id}>
                {p.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.parcId ? true : false}>
            {formik.errors.parcId}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prixBascule ? true : false}
            id="prixBascule"
            type="text"
            variant="outlined"
            label="PRIX BASCULE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixBascule}
            size="small"
          />
          <FormHelperText error={formik.errors.prixBascule ? true : false}>
            {formik.errors.prixBascule}
          </FormHelperText>
        </FormControl>

        <FormControl>
          <TextField
            error={formik.errors.prixSac ? true : false}
            id="prixSac"
            type="text"
            variant="outlined"
            label="PRIX SAC"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prixSac}
            size="small"
          />
          <FormHelperText error={formik.errors.prixSac ? true : false}>
            {formik.errors.prixSac}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.quantiteStockee ? true : false}
            id="quantiteStockee"
            type="text"
            variant="outlined"
            label="QUANTITE STOCKEE"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.quantiteStockee}
            size="small"
          />
          <FormHelperText error={formik.errors.quantiteStockee ? true : false}>
            {formik.errors.quantiteStockee}
          </FormHelperText>
        </FormControl>

        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button color="primary" type="submit">
            Enregistrer
          </Button>
          <Button
            color="secondary"
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default ParcsPricesFormModals;
