import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import useStyles from "./styles";
import useCommonStyles from "../styles";
import Avatar from "@mui/material/Avatar";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import { toast } from "react-toastify";
import DefaultAvatar from "../../../../assets/defaultUser.png";

function NavLoginLogout() {
  const { isLogin, token, handleLogout } = useAuthenticationContext();
  const classes = useStyles();
  const commonNavItemsClasses = useCommonStyles();
  const { user } = useAuthenticationContext();
  const history = useHistory();

  return (
    <div className={classes.navLoginLogout}>
      {isLogin ? (
        <div className={classes.navLogin}>
          <Avatar
            fontSize="large"
            alt={user.username}
            className={commonNavItemsClasses.icon}
            src={
              user.photo_base64 === null || user.photo_base64 === ""
                ? DefaultAvatar
                : user.photo_base64
            }
          />
          <NavDropdown
            title={
              <span className="text-white">
                {`${user.user !== undefined && user.user.first_name} ${
                  user.user !== undefined && user.user.last_name
                }`}
                <span className={`text-white ${classes.userRole}`}>{` {${
                  (user.is_administrateur && "Administrateur") ||
                  (user.is_admin_saed && "Administrateur SAED") ||
                  (user.is_admin_agregateur && "Admin Agrégateur") ||
                  (user.is_admin_groupement && "Admin Groupement") ||
                  (user.is_resp_point_collecte && "Resp Point Collecte") ||
                  (user.is_admin_banque && "Admin Banque") ||
                  (user.is_payeur_banque && "Payeur Banque") ||
                  (user.is_agent_arm && "Agent ARM") ||
                  (user.is_admin_arm && "Admin ARM") ||
                  (user.is_agent_marche && "Agent Marché") ||
                  (user.is_commercant && "Commercant") ||
                  (user.is_producteur && "Producteur")
                }}`}</span>
              </span>
            }
            id="basic-nav-dropdown"
            className={`text-white ${commonNavItemsClasses.navDropDown}`}
            color="inherit"
          >
            <NavDropdown.Item as="div">
              <div
                className={`text-black ${commonNavItemsClasses.link}`}
                style={{
                  marginLeft: "17px",
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  let response = await handleLogout(token);
                  if (response.status === 200) {
                    toast.success("Vous etes déconnecte!", {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    history.push("/connexion");
                  } else {
                    console.log(response);
                  }
                }}
              >
                Déconnexion
              </div>
            </NavDropdown.Item>
            <NavDropdown.Item as="div">
              <Link
                to="/profile"
                className={`text-black ${commonNavItemsClasses.link}`}
                style={{ marginLeft: "17px", fontSize: "1rem" }}
              >
                Profile
              </Link>
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      ) : (
        <Nav>
          <Nav.Link as="div">
            <Link
              to="/connexion"
              className={`text-white ${commonNavItemsClasses.link}`}
            >
              Connexion
            </Link>
          </Nav.Link>
        </Nav>
      )}
    </div>
  );
}

export default NavLoginLogout;
