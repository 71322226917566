import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import {
  LIST_AGREGATORS_DATA_URL,
  LIST_COLLECT_POINTS_DATA_URL,
  LIST_GROUPMENTS_DATA_URL,
  LIST_USERS_DATA_URL,
} from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useOrganizationsContext } from "../../../utils/contexts/OrganizationsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import { useUsersContext } from "../../../utils/contexts/UsersProvider";
import UsersAutresTableContainer from "./CustomTable/UsersAutresTableContainer";

function UsersAutresContainer() {
  const { token } = useAuthenticationContext();
  const { setListUsers } = useUsersContext();
  const { isUserListUpdated, setIsUserListUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllUsers = async () => {
        try {
          const result = await apiInstance.get(LIST_USERS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });

          setListUsers(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };

      fetchAllUsers();
      if (isUserListUpdated) {
        setIsUserListUpdated(false);
      }
    }
  }, [
    token,
    setIsUserListUpdated,
    isUserListUpdated,
    setListUsers,
    setProgressState,
  ]);

  React.useEffect(() => {
    if (isUserListUpdated) {
      const fetchAllUsers = async () => {
        try {
          const result = await apiInstance.get(LIST_USERS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });

          setListUsers(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          return err;
        }
      };
      fetchAllUsers();
      setIsUserListUpdated(false);
    }
  }, [isUserListUpdated, setIsUserListUpdated, setListUsers, setProgressState]);
  return (
    <CommonPagesLayout>
      <h1>Utilisateurs</h1>
      {progressState ? <CircularProgress /> : <UsersAutresTableContainer />}
    </CommonPagesLayout>
  );
}

export default UsersAutresContainer;
