import {
  FormControl,
  Modal,
  TextField,
  Button,
  ButtonGroup,
  FormHelperText,
  Typography,
  NativeSelect,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useOrganizationsContext } from "../../../../utils/contexts/OrganizationsProvider";
import { useRecordsContext } from "../../../../utils/contexts/RecordsProvider";
import SelectGroupForRecords from "../../../SelectInput/SelectZoneGroup/SelectZoneGroup";
import { v4 as uuidv4 } from "uuid";
import FormModalsWrapper from "../FormModalsWrapper";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
  paper: {
    "& > *": {
      width: "60ch",
    },
    [theme.breakpoints.down("md")]: {
      "& > *": {
        width: "35ch",
      },
    },
    background: "rgba( 255, 255, 255, 0.90 )",
    boxSshadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.50 )",
    backdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    height: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  form: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    justifyContent: "space-between",
    alignItems: "center",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "20px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#172b18",
      borderRadius: "20px",
      border: "6px solid transparent",
      backgroundClip: "content-box",
    },
  },
  formRow: {
    marginTop: "10px",
    marginBottom: "10px",
    [theme.breakpoints.down("md")]: {
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
}));
function ProductorsFormModal({
  row,
  rows,
  open,
  handleClose,
  setRows,
  formAction,
}) {
  const classes = useStyles();
  const { listGroupments, createProductor, updateProductor } =
    useOrganizationsContext();
  const { listZones } = useRecordsContext();

  const enumSexe = [
    { nom: "Homme", id: "H" },
    { nom: "Femme", id: "F" },
  ];
  const enumAge = [
    { nom: "J", id: "J" },
    { nom: "A1", id: "A1" },
    { nom: "A2", id: "A2" },
  ];
  const formik = useFormik({
    initialValues: {
      id: row.id,
      nom: row.nom,
      prenom: row.prenom,
      age: row.age,
      sexe: row.sexe,
      telephone: row.telephone,
      adresse: row.adresse,
      cni: row.cni,
      groupement: row.groupement?.id,
      reference: row.reference,
      region: row.zone !== undefined ? row.zone.region : "",
      departement: row.zone !== undefined ? row.zone.departement : "",
      commune: row.zone !== undefined ? row.zone.commune : "",
    },
    enableReinitialize: true,
    validateOnChange: true,

    validationSchema: Yup.object({
      id: Yup.string(),
      nom: Yup.string().required("Champ requis!"),
      prenom: Yup.string().required("Champ requis!"),
      age: Yup.string().required("Champ requis!"),
      sexe: Yup.string().required("Champ requis!"),
      telephone: Yup.string().required("Champ requis!"),
      adresse: Yup.string().notRequired().nullable(true).default(null),
      cni: Yup.string().notRequired().nullable(true).default(null),
      groupement: Yup.string().required("Champ requis!"),
      reference: Yup.string().notRequired(),
      region: Yup.string().required("Champ requis!"),
      departement: Yup.string().required("Champ requis!"),
      commune: Yup.string().required("Champ requis!"),
    }),

    onSubmit: (values, onSubmitProps) => {
      let tempZoneId = {};

      tempZoneId = listZones.find(
        (e) =>
          e.region === values.region &&
          e.departement === values.departement &&
          e.commune === values.commune
      );

      let data = {
        reference:
          values.reference === "" || values.reference === undefined
            ? uuidv4()
            : values.reference,
        nom: values.nom,
        prenom: values.prenom,
        sexe: values.sexe,
        age: values.age,
        telephone: values.telephone,
        cni: values.cni === "" ? null : values.cni,
        adresse: values.adresse === "" ? null : values.adresse,
        groupement_id: values.groupement,
        zone_id: tempZoneId.id,
      };
      onSubmitProps.setSubmitting(true);
      if (formAction === "add") {
        createProductor(data);
      } else if (formAction === "update") {
        updateProductor(values.id, data);
      }
      handleClose();
      onSubmitProps.resetForm();
      onSubmitProps.setSubmitting(false);
    },
  });

  return (
    <FormModalsWrapper
      open={open}
      handleClose={handleClose}
      title={
        formAction === "add" ? "NOUVEAU PRODUCTEUR" : "MODIFIER PRODUCTEUR"
      }
    >
      <form
        onSubmit={formik.handleSubmit}
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.nom ? true : false}
            id="nom"
            type="text"
            variant="outlined"
            label="Nom"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nom}
            size="small"
          />
          <FormHelperText error={formik.errors.nom ? true : false}>
            {formik.errors.nom}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.prenom ? true : false}
            id="prenom"
            type="text"
            variant="outlined"
            label="Prenom"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.prenom}
            size="small"
          />
          <FormHelperText error={formik.errors.prenom ? true : false}>
            {formik.errors.prenom}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="age"
            value={formik.values.age}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              AGE
            </option>
            {enumAge.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.age ? true : false}>
            {formik.errors.age}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="sexe"
            value={formik.values.sexe}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              SEXE
            </option>
            {enumSexe.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.sexe ? true : false}>
            {formik.errors.sexe}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.telephone ? true : false}
            id="telephone"
            type="text"
            variant="outlined"
            label="Telephone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.telephone}
            size="small"
          />
          <FormHelperText error={formik.errors.telephone ? true : false}>
            {formik.errors.telephone}
          </FormHelperText>
        </FormControl>
        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.adresse ? true : false}
            id="adresse"
            type="text"
            variant="outlined"
            label="Adresse"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.adresse}
            size="small"
          />
          <FormHelperText error={formik.errors.adresse ? true : false}>
            {formik.errors.adresse}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <TextField
            error={formik.errors.cni ? true : false}
            id="cni"
            type="text"
            variant="outlined"
            label="Cni"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.cni}
            size="small"
          />
          <FormHelperText error={formik.errors.cni ? true : false}>
            {formik.errors.cni}
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formRow}>
          <NativeSelect
            id="groupement"
            value={formik.values.groupement}
            onChange={formik.handleChange}
          >
            <option value="" disabled={formAction === "update" ? true : false}>
              Choisir un groupement
            </option>
            {listGroupments.map((c, index) => (
              <option key={index} value={c.id}>
                {c.nom}
              </option>
            ))}
          </NativeSelect>
          <FormHelperText error={formik.errors.groupement ? true : false}>
            {formik.errors.groupement}
          </FormHelperText>
        </FormControl>
        <SelectGroupForRecords formik={formik} formAction={formAction} />
        <ButtonGroup
          className={classes.formRow}
          variant="contained"
          aria-label="text primary button group"
        >
          <Button color="primary" type="submit">
            Enregistrer
          </Button>
          <Button color="secondary" onClick={handleClose}>
            Annuler
          </Button>
        </ButtonGroup>
      </form>
    </FormModalsWrapper>
  );
}

export default ProductorsFormModal;
