import { CircularProgress } from "@mui/material";
import React from "react";
import CommonPagesLayout from "../../../components/layouts/Common/CommonPagesLayout";
import { LIST_MARKETS_DATA_URL } from "../../../utils/api/apiUrls";
import apiInstance from "../../../utils/api/axios.config";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";
import { useMarketsContext } from "../../../utils/contexts/MarketsProvider";
import { useUpdatePagesStateContext } from "../../../utils/contexts/UpdatePagesStateProvider";
import MarketsListTableContainer from "./CustomTable/MarketsListTableContainer";

function MarketsListContainer() {
  const { token } = useAuthenticationContext();
  const { setListMarkets } = useMarketsContext();
  const { setIsListMarketsUpdated, isListMarketsUpdated } =
    useUpdatePagesStateContext();
  const [progressState, setProgressState] = React.useState(true);

  React.useEffect(() => {
    if (token !== "") {
      const fetchAllMarkets = async () => {
        try {
          const result = await apiInstance.get(LIST_MARKETS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListMarkets(result.data);
          setProgressState(false);
        } catch (err) {
          console.log(err);
          setProgressState(false);
        }
      };

      fetchAllMarkets();
    }
  }, [token, setListMarkets, setProgressState]);

  React.useEffect(() => {
    if (isListMarketsUpdated) {
      const fetchAllMarkets = async () => {
        try {
          const result = await apiInstance.get(LIST_MARKETS_DATA_URL, {
            headers: { Authorization: `Token ${token}` },
          });
          setListMarkets(result.data);
          setProgressState(false);
        } catch (err) {
          setProgressState(false);
          console.log(err);
        }
      };

      fetchAllMarkets();
      setIsListMarketsUpdated(false);
    }
  }, [isListMarketsUpdated, setIsListMarketsUpdated, setListMarkets, token]);

  return (
    <CommonPagesLayout>
      <h1>Listes Marchés</h1>
      {progressState ? <CircularProgress /> : <MarketsListTableContainer />}
    </CommonPagesLayout>
  );
}

export default MarketsListContainer;
