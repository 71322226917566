import React from "react";
import CommonPagesLayout from "../../components/layouts/Common/CommonPagesLayout";

function UnauthorizedPageContainer() {
  return (
    <CommonPagesLayout>
      <div>Vous n'etes pas autorise</div>
    </CommonPagesLayout>
  );
}

export default UnauthorizedPageContainer;
