import { FormControl, MenuItem, NativeSelect, Select } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import React from "react";
import { useProductsContext } from "../../../../../utils/contexts/ProductsProvider";
import Items from "./Items/Items";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
}));

function SelectProduct({ setInputValue, inputValue }) {
  const classes = useStyles();

  const handleChangeProduct = (event) => {
    setInputValue(event.target.value);
  };

  const { listProducts } = useProductsContext();

  return (
    <React.Fragment>
      <FormControl
        className={classes.formControl}
        sx={{ m: 1, minWidth: 180 }}
        variant="filled"
      >
        <InputLabel id="demo-simple-select-autowidth-label" shrink>
          Produits
        </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={inputValue}
          onChange={handleChangeProduct}
          label="Produits"
        >
          {listProducts.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item.nom}
                className={classes.itemText}
              >
                {item.nom}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

export default SelectProduct;
