import React from "react";
import CustomTable from "../../../../../components/Table/CustomTable/CustomTable";
import DisplayProductVarietiesTableRows from "../../../../../components/Table/CustomTable/TableContext/ProductVarietiesTableCells/DisplayProductVarietiesTableRows";
import { exportTableListVarietesOfProduct } from "../../../../../services/filesTransfer";
import VarietiesFormModals from "../../../../../ui/Modals/FormModals/Products/VarietiesFormModals";
import { useProductsContext } from "../../../../../utils/contexts/ProductsProvider";
import templateImport from "../../../../../assets/download-templates/template_variete.xlsx";
import { useAuthenticationContext } from "../../../../../utils/contexts/AuthenticationProvider";

function VarietiesListOfAProductTableContainer({ id, product }) {
  const { deleteVariety } = useProductsContext();
  const { user } = useAuthenticationContext();
  const formModalInitialRow = {
    id: "",
    nom: "",
    description: "",
  };
  const headCells = [
    { label: "Image", id: "IMAGE" },
    { label: "Nom", id: "NOM" },
    { label: "Description", id: "DESCRIPTION" },
    { label: "Origine", id: "ORIGINE" },
  ];
  const [jsonToSend, setJSONToSend] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [inputSearchValue, setInputSearchValue] = React.useState("");
  const [formAction, setFormAction] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterValue, setFilterValue] = React.useState(headCells[0].id || "");
  const [filter, setFilter] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (event) => {
    let searchQuery = event.target.value;
    setInputSearchValue(searchQuery);
    setFilter({
      fn: (items) => {
        if (searchQuery === "") return items;
        else
          return items.filter((x) => {
            return (
              x["nom"].toLowerCase().includes(searchQuery.toLowerCase()) ||
              x["description"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["origine"].toLowerCase().includes(searchQuery.toLowerCase())
            );
          });
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isASC = orderBy === cellId && order === "asc";
    setOrder(isASC ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = React.useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPaginationSortingAndFiltering = () => {
    return stableSort(filter.fn(rows), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  React.useEffect(() => {
    setRows(product?.varietes);
  }, [product]);

  React.useEffect(() => {
    if (jsonToSend.length > 0) {
      jsonToSend.forEach((json) => {
        json["produit_id"] = JSON.parse(id);
      });
    }
  }, [jsonToSend, id]);

  React.useEffect(() => {
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      setFilteredRows(stabilizedThis.map((el) => el[0]));
    }
    stableSort(filter.fn(rows), getComparator(order, orderBy));
  }, [
    inputSearchValue,
    setFilteredRows,
    rows,
    order,
    orderBy,
    filter,
    getComparator,
  ]);

  return (
    <React.Fragment>
      <CustomTable
        tableName={`varietes_produit_${product?.nom}`}
        TableContext={DisplayProductVarietiesTableRows}
        headCells={headCells}
        rows={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        emptyRows={emptyRows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        handleSortRequest={handleSortRequest}
        recordsAfterPaginationSortingAndFiltering={
          recordsAfterPaginationSortingAndFiltering
        }
        filterLabel="Filtrer par:"
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        stableSort={stableSort}
        getComparator={getComparator}
        filter={filter}
        setRows={setRows}
        formAction={formAction}
        setFormAction={setFormAction}
        enableMoreDetailsIcon={false}
        FormModal={VarietiesFormModals}
        enableEdit={true}
        formModalInitialRow={formModalInitialRow}
        customId={id}
        handleDeleteRow={deleteVariety}
        disableSelectFilterValue={true}
        setInputSearchValue={setInputSearchValue}
        filteredRows={filteredRows}
        inputSearchValue={inputSearchValue}
        customExport={exportTableListVarietesOfProduct}
        setJSONToSend={setJSONToSend}
        disableTemplateButton={false}
        templateFileName="template_variete"
        templateFilePath={templateImport}
        disableDeleteIcon={user.is_administrateur ? false : true}
      />
    </React.Fragment>
  );
}

export default VarietiesListOfAProductTableContainer;
