import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const exportTable = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const importFileTable = (file /*:File*/, setJSONToSend) => {
  /* Boilerplate to set up FileReader */
  const reader = new FileReader();
  const rABS = !!reader.readAsBinaryString;
  reader.onload = (e) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 0 });
    /* Update state */
    setJSONToSend(data);
  };
  if (rABS) reader.readAsBinaryString(file);
  else reader.readAsArrayBuffer(file);
};

export const exportTableProduits = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "description" };
  workSheet["C1"] = { t: "s", v: "varietes" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    workSheet[`E${i + 2}`].length > 0 &&
      workSheet[`E${i + 2}`].forEach((vr) => {
        concactVr = concactVr + `${vr.nom},`;
      });

    concactVr = { t: "s", v: concactVr };
    workSheet[`C${i + 2}`] = concactVr;
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
  });

  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListVarietesOfProduct = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "description" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
  });

  delete workSheet["C1"];
  delete workSheet["D1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListVarietes = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "description" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
  });

  delete workSheet["C1"];
  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListAgregateurs = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "zones" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    workSheet[`C${i + 2}`].length > 0 &&
      workSheet[`C${i + 2}`].forEach((vr) => {
        concactVr = concactVr + `${vr.region}|${vr.departement}|${vr.commune},`;
      });

    concactVr = { t: "s", v: concactVr };
    workSheet[`B${i + 2}`] = concactVr;
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
  });

  delete workSheet["C1"];
  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCollectPoints = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "telephone" };
  workSheet["C1"] = { t: "s", v: "superficie" };
  workSheet["D1"] = { t: "s", v: "latitude" };
  workSheet["E1"] = { t: "s", v: "longitude" };
  workSheet["F1"] = { t: "s", v: "zone" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`H${i + 2}`];

    concactVr = {
      t: "s",
      v: `${concactVr.region}|${concactVr.departement}|${concactVr.commune},`,
    };
    workSheet[`F${i + 2}`] = concactVr;
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
    delete workSheet[`L${i + 2}`];
  });

  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];
  delete workSheet["L1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListGroupements = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "agregateur" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`C${i + 2}`];

    concactVr = {
      t: "s",
      v: `${concactVr.nom}`,
    };

    workSheet[`B${i + 2}`] = concactVr;
    delete workSheet[`C${i + 2}`];
    delete workSheet[`D${i + 2}`];
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
  });

  delete workSheet["C1"];
  delete workSheet["D1"];
  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListProducteurs = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "reference" };
  workSheet["B1"] = { t: "s", v: "prenom" };
  workSheet["C1"] = { t: "s", v: "nom" };
  workSheet["D1"] = { t: "s", v: "sexe" };
  workSheet["E1"] = { t: "s", v: "age" };
  workSheet["F1"] = { t: "s", v: "telephone" };
  workSheet["G1"] = { t: "s", v: "cni" };
  workSheet["H1"] = { t: "s", v: "adresse" };
  workSheet["I1"] = { t: "s", v: "groupement" };
  workSheet["J1"] = { t: "s", v: "zone" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
    workSheet[`F${i + 2}`] = workSheet[`G${i + 2}`];
    workSheet[`G${i + 2}`] = workSheet[`H${i + 2}`];
    workSheet[`H${i + 2}`] = workSheet[`I${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr1 = "";
    let concactVr2 = "";
    concactVr1 = workSheet[`J${i + 2}`];
    concactVr2 = workSheet[`K${i + 2}`];

    concactVr1 = {
      t: "s",
      v: `${concactVr1.nom}`,
    };

    concactVr2 = {
      t: "s",
      v: `${concactVr2.region}|${concactVr2.departement}|${concactVr2.commune},`,
    };
    workSheet[`I${i + 2}`] = concactVr1;
    workSheet[`J${i + 2}`] = concactVr2;

    delete workSheet[`K${i + 2}`];
    delete workSheet[`L${i + 2}`];
    delete workSheet[`M${i + 2}`];
    delete workSheet[`N${i + 2}`];
    delete workSheet[`O${i + 2}`];
  });

  delete workSheet["K1"];
  delete workSheet["L1"];
  delete workSheet["M1"];
  delete workSheet["N1"];
  delete workSheet["O1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCommercants = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "prenom" };
  workSheet["B1"] = { t: "s", v: "nom" };
  workSheet["C1"] = { t: "s", v: "telephone" };
  workSheet["D1"] = { t: "s", v: "adresse" };
  workSheet["E1"] = { t: "s", v: "cni" };
  workSheet["F1"] = { t: "s", v: "agregateurs" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    workSheet[`G${i + 2}`].length > 0 &&
      workSheet[`G${i + 2}`].forEach((vr) => {
        concactVr = concactVr + `${vr.nom},`;
      });

    concactVr = { t: "s", v: concactVr };
    workSheet[`F${i + 2}`] = concactVr;
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
  });

  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCoxeurs = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "prenom" };
  workSheet["B1"] = { t: "s", v: "nom" };
  workSheet["C1"] = { t: "s", v: "telephone" };
  workSheet["D1"] = { t: "s", v: "cni" };
  workSheet["E1"] = { t: "s", v: "point_collecte" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`F${i + 2}`];

    concactVr = { t: "s", v: concactVr.nom };
    workSheet[`E${i + 2}`] = concactVr;
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
  });
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListLettreVoitures = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "reference" };
  workSheet["B1"] = { t: "s", v: "chauffeur" };
  workSheet["C1"] = { t: "s", v: "camion_immat" };
  workSheet["D1"] = { t: "s", v: "destination" };
  workSheet["E1"] = { t: "s", v: "commercant" };
  workSheet["F1"] = { t: "s", v: "commercant_autre" };
  workSheet["G1"] = { t: "s", v: "mvts" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`E${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`F${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`G${i + 2}`];

    concactVr = {
      t: "s",
      v: `nom: ${concactVr.nom}|prenom: ${concactVr.prenom}|telephone:${concactVr.telephone}`,
    };
    workSheet[`E${i + 2}`] = concactVr;
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    workSheet[`H${i + 2}`].length > 0 &&
      workSheet[`H${i + 2}`].forEach((vr) => {
        concactVr = concactVr + `reference: ${vr.refererence},`;
      });

    concactVr = { t: "s", v: concactVr };
    workSheet[`G${i + 2}`] = concactVr;
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
    delete workSheet[`L${i + 2}`];
  });

  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];
  delete workSheet["L1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListCodeDommages = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "code" };
  workSheet["B1"] = { t: "s", v: "nom" };
  workSheet["C1"] = { t: "s", v: "description" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`D${i + 2}`];
  });

  delete workSheet["D1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListMarches = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "superficie" };
  workSheet["C1"] = { t: "s", v: "frequence" };
  workSheet["D1"] = { t: "s", v: "region" };
  workSheet["E1"] = { t: "s", v: "departement" };
  workSheet["F1"] = { t: "s", v: "commune" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
    workSheet[`B${i + 2}`] = workSheet[`C${i + 2}`];
    workSheet[`C${i + 2}`] = workSheet[`D${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`E${i + 2}`];
    console.log(concactVr);

    workSheet[`D${i + 2}`] = { t: "s", v: concactVr.region };
    workSheet[`E${i + 2}`] = { t: "s", v: concactVr.departement };
    workSheet[`F${i + 2}`] = { t: "s", v: concactVr.commune };
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
  });

  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListMarchesPrix = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "variete" };
  workSheet["B1"] = { t: "s", v: "origine" };
  workSheet["C1"] = { t: "s", v: "marche" };
  workSheet["D1"] = { t: "s", v: "prix_detaillant" };
  workSheet["E1"] = { t: "s", v: "prix_demi_gros" };
  workSheet["F1"] = { t: "s", v: "sac_demi_gros" };
  workSheet["G1"] = { t: "s", v: "prix_gros" };
  workSheet["H1"] = { t: "s", v: "quantite_stockee" };

  tableData.forEach((tb, i) => {
    let concactVr1 = "";
    let concactVr2 = "";
    let concactVr3 = "";
    concactVr1 = workSheet[`C${i + 2}`];
    concactVr2 = workSheet[`E${i + 2}`];

    concactVr1 = {
      t: "s",
      v: concactVr1.nom,
    };

    concactVr3 = { t: "s", v: workSheet[`C${i + 2}`]["origine"] };

    concactVr2 = {
      t: "s",
      v: concactVr2.nom,
    };

    workSheet[`A${i + 2}`] = concactVr1;
    workSheet[`B${i + 2}`] = concactVr3;
    workSheet[`C${i + 2}`] = concactVr2;
  });

  tableData.forEach((tb, i) => {
    workSheet[`D${i + 2}`] = workSheet[`F${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`G${i + 2}`];
    workSheet[`F${i + 2}`] = workSheet[`H${i + 2}`];
    workSheet[`G${i + 2}`] = workSheet[`I${i + 2}`];
    workSheet[`H${i + 2}`] = workSheet[`J${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
    delete workSheet[`L${i + 2}`];
    delete workSheet[`M${i + 2}`];
    delete workSheet[`N${i + 2}`];
    delete workSheet[`O${i + 2}`];
  });

  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];
  delete workSheet["L1"];
  delete workSheet["M1"];
  delete workSheet["N1"];
  delete workSheet["O1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListParcsPrix = (
  tableData,
  fileName,
  fileExtension
) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "variete" };
  workSheet["B1"] = { t: "s", v: "parc" };
  workSheet["C1"] = { t: "s", v: "prix_bascule" };
  workSheet["D1"] = { t: "s", v: "prix_sac" };
  workSheet["E1"] = { t: "s", v: "quantite_stockee" };

  tableData.forEach((tb, i) => {
    let concactVr1 = "";
    let concactVr2 = "";
    concactVr1 = workSheet[`C${i + 2}`];
    concactVr2 = workSheet[`E${i + 2}`];

    concactVr1 = {
      t: "s",
      v: concactVr1.nom,
    };

    concactVr2 = {
      t: "s",
      v: concactVr2.nom,
    };

    workSheet[`A${i + 2}`] = concactVr1;
    workSheet[`B${i + 2}`] = concactVr2;
  });

  tableData.forEach((tb, i) => {
    workSheet[`C${i + 2}`] = workSheet[`F${i + 2}`];
    workSheet[`D${i + 2}`] = workSheet[`G${i + 2}`];
    workSheet[`E${i + 2}`] = workSheet[`H${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
    delete workSheet[`I${i + 2}`];
    delete workSheet[`J${i + 2}`];
    delete workSheet[`K${i + 2}`];
    delete workSheet[`L${i + 2}`];
  });

  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];
  delete workSheet["I1"];
  delete workSheet["J1"];
  delete workSheet["K1"];
  delete workSheet["L1"];

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};

export const exportTableListParcs = (tableData, fileName, fileExtension) => {
  const workSheet = XLSX.utils.json_to_sheet(tableData);

  workSheet["A1"] = { t: "s", v: "nom" };
  workSheet["B1"] = { t: "s", v: "region" };
  workSheet["C1"] = { t: "s", v: "departement" };
  workSheet["D1"] = { t: "s", v: "commune" };

  tableData.forEach((tb, i) => {
    workSheet[`A${i + 2}`] = workSheet[`B${i + 2}`];
  });

  tableData.forEach((tb, i) => {
    let concactVr = "";
    concactVr = workSheet[`C${i + 2}`];

    workSheet[`B${i + 2}`] = { t: "s", v: concactVr.region };
    workSheet[`C${i + 2}`] = { t: "s", v: concactVr.departement };
    workSheet[`D${i + 2}`] = { t: "s", v: concactVr.commune };
  });

  tableData.forEach((tb, i) => {
    delete workSheet[`E${i + 2}`];
    delete workSheet[`F${i + 2}`];
    delete workSheet[`G${i + 2}`];
    delete workSheet[`H${i + 2}`];
  });

  delete workSheet["E1"];
  delete workSheet["F1"];
  delete workSheet["G1"];
  delete workSheet["H1"];

  console.log(workSheet);

  const workBook = { Sheets: { data: workSheet }, SheetNames: ["data"] };
  // Buffer
  const buffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });

  const data = new Blob([buffer], { type: fileType });

  // Download
  FileSaver.saveAs(data, fileName + `.` + fileExtension);
};
