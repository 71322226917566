import React from "react";
import { NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import HouseIcon from "@mui/icons-material/House";
import useStyles from "./styles";
import useCommonStyles from "../styles";

function NavRecords() {
  const classes = useStyles();
  const commonNavItemsClasses = useCommonStyles();
  return (
    <div className={classes.navRecords}>
      <HouseIcon
        color="inherit"
        className={commonNavItemsClasses.icon}
        fontSize="large"
      />
      <NavDropdown
        title={<span className="text-white">Enregistrements</span>}
        id="basic-nav-dropdown"
        className={commonNavItemsClasses.navDropDown}
      >
        <NavDropdown.Item as="div">
          <Link
            to="/enregistrements/entreestocks"
            className={commonNavItemsClasses.link}
          >
            Entrées Stocks
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link
            to="/enregistrements/codedommages"
            className={commonNavItemsClasses.link}
          >
            Codes Dommages
          </Link>
        </NavDropdown.Item>
        <NavDropdown.Item as="div">
          <Link
            to="/enregistrements/lettrevoitures"
            className={commonNavItemsClasses.link}
          >
            Lettres Voitures
          </Link>
        </NavDropdown.Item>

        <NavDropdown.Item as="div">
          <Link
            to="/enregistrements/sortiedommages"
            className={commonNavItemsClasses.link}
          >
            Sorties Dommages
          </Link>
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
}

export default NavRecords;
