import { CircularProgress } from "@mui/material";
import React from "react";
import CustomTable from "../../../../components/Table/CustomTable/CustomTable";
import DisplayUsersAutresTableRow from "../../../../components/Table/CustomTable/TableContext/UsersTableCells/DisplayUsersAutresTableRow";
import UsersFormModal from "../../../../ui/Modals/FormModals/Users/UsersFormModal";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import { useUsersContext } from "../../../../utils/contexts/UsersProvider";

function UsersAutresTableContainer() {
  const { listUsers, deleteUser } = useUsersContext();
  const { user } = useAuthenticationContext();

  const formModalInitialRow = {
    id: "",
    avatar: "",
    nom: "",
    prenom: "",
    email: "",
    password: "",
    passwordConfirmation: "",
    username: "",
    agregateur: "",
    groupement: "",
    point_collecte: "",
    role: "",
  };

  const headCells = [
    { label: "Avatar", id: "AVATAR", disabled: true, disableSort: true },
    { label: "Prenom", id: "FIRST_NAME" },
    { label: "Nom", id: "LAST_NAME" },
    { label: "Email", id: "EMAIL" },
    { label: "Username", id: "USERNAME" },
    { label: "Role", id: "ROLE_NAMES" },
    { label: "Organisation", id: "ORGANIZATION" },
  ];

  const [formAction, setFormAction] = React.useState("");
  const [inputSearchValue, setInputSearchValue] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterValue, setFilterValue] = React.useState(headCells[1].id || "");
  const [filter, setFilter] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (event) => {
    let searchQuery = event.target.value;
    setInputSearchValue(searchQuery);
    setFilter({
      fn: (items) => {
        if (searchQuery === "") {
          return items;
        } else {
          let result = items.filter((x) => {
            return (
              x["user"]["username"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["user"]["last_name"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["user"]["first_name"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["user"]["email"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              (x.groupement !== null &&
                x["groupement"]["nom"]
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())) ||
              (x.agregateur !== null &&
                x["agregateur"]["nom"]
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())) ||
              (x.point_collecte !== null &&
                x["point_collecte"]["nom"]
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()))
            );
          });

          return result;
        }
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isASC = orderBy === cellId && order === "asc";
    setOrder(isASC ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = React.useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPaginationSortingAndFiltering = () => {
    return stableSort(filter.fn(rows), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  React.useEffect(() => {
    setRows(listUsers);
  }, [listUsers]);

  React.useEffect(() => {
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      setFilteredRows(stabilizedThis.map((el) => el[0]));
    }
    stableSort(filter.fn(rows), getComparator(order, orderBy));
  }, [
    inputSearchValue,
    setFilteredRows,
    getComparator,
    rows,
    order,
    orderBy,
    filter,
  ]);

  return (
    <React.Fragment>
      <CustomTable
        tableName="utilisateur"
        TableContext={DisplayUsersAutresTableRow}
        headCells={headCells}
        rows={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        emptyRows={emptyRows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        handleSortRequest={handleSortRequest}
        recordsAfterPaginationSortingAndFiltering={
          recordsAfterPaginationSortingAndFiltering
        }
        filterLabel="Filtrer par:"
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        stableSort={stableSort}
        getComparator={getComparator}
        filter={filter}
        setRows={setRows}
        formModalInitialRow={formModalInitialRow}
        FormModal={UsersFormModal}
        disableAddButton={false}
        disableDownloadButton={true}
        disableLoadButton={true}
        enableEdit={true}
        formAction={formAction}
        setFormAction={setFormAction}
        handleDeleteRow={deleteUser}
        disableSelectFilterValue={true}
        inputSearchValue={inputSearchValue}
        setInputSearchValue={setInputSearchValue}
        filteredRows={filteredRows}
        disableTemplateButton={true}
        enableMoreDetailsIcon={true}
        moreDetailsPath="/utilisateurs/"
        disableDeleteIcon={user.is_administrateur ? false : true}
      />
    </React.Fragment>
  );
}

export default UsersAutresTableContainer;
