import React from "react";
import Footer from "../../Footer/Footer";
import MainContainer from "./Main/MainContainer";
import CustomNavBar from "../../../ui/Navbar/CustomNavBar";
import PropTypes from "prop-types";

/**
 * Component Description:
 * This component define the layout for common pages containers,
 * like Dashboard Container, Markets Containers ...
 * It need to be wrapped inside the container of the component.
 */
function CommonPagesLayout({ children }) {
  return (
    <React.Fragment>
      <CustomNavBar />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </React.Fragment>
  );
}

CommonPagesLayout.propTypes = {
  /**
   * Decription:
   * Node children.
   */
  children: PropTypes.node.isRequired,
};

export default CommonPagesLayout;
