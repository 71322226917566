import moment from "moment";
import React from "react";
import CustomTable from "../../../../components/Table/CustomTable/CustomTable";
import DisplayParcsPricesTableRows from "../../../../components/Table/CustomTable/TableContext/ParcsPrices/DisplayParcsPricesTableRows";
import { exportTableListMarchesPrix, exportTableListParcsPrix } from "../../../../services/filesTransfer";
import ParcsPricesFormModals from "../../../../ui/Modals/FormModals/Parcs/ParcsPricesFormModals";
import { useAuthenticationContext } from "../../../../utils/contexts/AuthenticationProvider";
import { useParcsContext } from "../../../../utils/contexts/ParcsProvider";

function ParcsPricesTableContainer() {
  const { user } = useAuthenticationContext();
  const formModalInitialRow = {
    id: "",
    varietyId: "",
    parcId: "",
    prixBascule: "0.0",
    prixSac: "0.0",
    quantiteStockee: "",
  };

  const { listParcsPrices, deleteParcPrice } = useParcsContext();

  const headCells = [
    { label: "Nom du Parc", id: "PARC_NOM" },
    { label: "Nom de la variete", id: "VARIETE_NOM" },
    { label: "Prix Bascule", id: "PRIX_BASCULE" },
    { label: "Prix Sac", id: "PRIX_SAC" },
    { label: "Quantité Stockée", id: "QUANTITE_STOCKEE" },
  ];
  const [, setJSONToSend] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [filteredRows, setFilteredRows] = React.useState([]);
  const [inputSearchValue, setInputSearchValue] = React.useState("");
  const [formAction, setFormAction] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filterValue, setFilterValue] = React.useState(headCells[0].id || "");
  const [filter, setFilter] = React.useState({
    fn: (items) => {
      return items;
    },
  });
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(headCells[0].id);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const handleSearch = (event) => {
    let searchQuery = event.target.value;
    setInputSearchValue(searchQuery);
    setFilter({
      fn: (items) => {
        if (searchQuery === "") return items;
        else
          return items.filter((x) => {
            return (
              x["marche"]["nom"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              x["variete"]["nom"]
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              (x.unite_demi_gros !== null &&
                x["unite_demi_gros"]
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())) ||
              (x.quantite_demi_gros !== null &&
                x["quantite_demi_gros"] === searchQuery) ||
              (x.prix_demi_gros_min !== null &&
                x["prix_demi_gros_min"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_demi_gros_max !== null &&
                x["prix_demi_gros_max"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_gros_min !== null &&
                x["prix_gros_min"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_gros_max !== null &&
                x["prix_gros_max"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_detaillant_min !== null &&
                x["prix_detaillant_min"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_detaillant_max !== null &&
                x["prix_detaillant_max"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              (x.prix_dominant !== null &&
                x["prix_dominant"].toLowerCase() ===
                  searchQuery.toLowerCase()) ||
              x["date_statut"] === searchQuery ||
              x["date_statut_expiration"] === searchQuery ||
              (searchQuery.toLowerCase() === "oui" && x["statut"] === true) ||
              (searchQuery.toLowerCase() === "non" && x["statut"] === false)
            );
          });
      },
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSortRequest = (cellId) => {
    const isASC = orderBy === cellId && order === "asc";
    setOrder(isASC ? "desc" : "asc");
    setOrderBy(cellId);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const getComparator = React.useCallback((order, orderBy) => {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }, []);

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const recordsAfterPaginationSortingAndFiltering = () => {
    return stableSort(filter.fn(rows), getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    );
  };

  React.useEffect(() => {
    setRows(listParcsPrices);
  }, [listParcsPrices]);

  React.useEffect(() => {
    function stableSort(array, comparator) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      setFilteredRows(stabilizedThis.map((el) => el[0]));
    }
    stableSort(filter.fn(rows), getComparator(order, orderBy));
  }, [
    inputSearchValue,
    setFilteredRows,
    getComparator,
    filter,
    rows,
    order,
    orderBy,
  ]);

  React.useEffect(() => {
    if (startDate !== "" && endDate !== "" && listParcsPrices.length > 0) {
      let tempList = [];
      tempList = listParcsPrices.filter((ag) => {
        return moment(ag.date_statut).isBetween(startDate, endDate) && ag;
      });
      setRows(tempList);
    } else {
      setRows(listParcsPrices);
    }
  }, [listParcsPrices, startDate, endDate]);

  return (
    <React.Fragment>
      <CustomTable
        tableName="parcs-prix"
        TableContext={DisplayParcsPricesTableRows}
        headCells={headCells}
        rows={rows}
        rowsPerPage={rowsPerPage}
        page={page}
        emptyRows={emptyRows}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        order={order}
        orderBy={orderBy}
        handleSortRequest={handleSortRequest}
        recordsAfterPaginationSortingAndFiltering={
          recordsAfterPaginationSortingAndFiltering
        }
        filterLabel="Filtrer par:"
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        handleSearch={handleSearch}
        stableSort={stableSort}
        getComparator={getComparator}
        filter={filter}
        setRows={setRows}
        enableEdit={true}
        formModalInitialRow={formModalInitialRow}
        FormModal={ParcsPricesFormModals}
        handleDeleteRow={deleteParcPrice}
        formAction={formAction}
        setFormAction={setFormAction}
        disableSelectFilterValue={true}
        disableTemplateButton={true}
        disableLoadButton={true}
        setInputSearchValue={setInputSearchValue}
        filteredRows={filteredRows}
        inputSearchValue={inputSearchValue}
        customExport={exportTableListParcsPrix}
        setJSONToSend={setJSONToSend}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        enableDateStartEndSearch={true}
        toolTipMessage="Filtrer par date statut"
        disableDeleteIcon={user.is_administrateur ? false : true}
      />
    </React.Fragment>
  );
}

export default ParcsPricesTableContainer;
