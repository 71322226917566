import { createTheme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const theme = createTheme();

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accordion: {
    boxShadow: "4px 4px 4px 2px black",
    width: "50%",
    [theme.breakpoints.down('md')]: {
      width: "100%",
    },
  },
  showVariete: { display: "flex", gap: "20px", alignItems: "center" },
}));

export default useStyles;
