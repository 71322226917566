import React from "react";
import { useRecordsContext } from "../../../utils/contexts/RecordsProvider";
import { useZonesContext } from "../../../utils/contexts/ZonesProvider";
import SelectCommunes from "./SelectItems/Communes/SelectCommunes";
import SelectDepartments from "./SelectItems/Departments/SelectDepartments";
import SelectRegions from "./SelectItems/Regions/SelectRegions";

function SelectZoneGroup({ formik, formAction }) {
  const { listZonesOrderByRegionByDepartmentByCommune } = useZonesContext();

  return (
    <React.Fragment>
      <SelectRegions
        items={listZonesOrderByRegionByDepartmentByCommune}
        formik={formik}
      />
      <SelectDepartments
        items={listZonesOrderByRegionByDepartmentByCommune}
        formik={formik}
      />

      <SelectCommunes
        items={listZonesOrderByRegionByDepartmentByCommune}
        formik={formik}
      />
    </React.Fragment>
  );
}

export default SelectZoneGroup;
