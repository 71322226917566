import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    display: "flex",
    width: "100%",
    minHeight: "5rem",
    justifyContent: "space-evenly",
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  rootContainerItems: {
    flex: 0.3,
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
  },
  rootMedia: {
    width: "40%",
    marginBottom: "10px",
  },
  media: {
    height: 480,
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "30px",
  },
  avatar: {
    width: "10rem",
    height: "10rem",
    [theme.breakpoints.down("sm")]: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
  },
  typo: {
    fontSize: "5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

export default useStyles;
