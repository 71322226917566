import NavOrganizations from "../../ui/Navbar/NavItems/NavOrganizations/NavOrganizations";
import NavProducts from "../../ui/Navbar/NavItems/NavProducts/NavProducts";
import NavRecords from "../../ui/Navbar/NavItems/NavRecords/NavRecords";
import NavUsers from "../../ui/Navbar/NavItems/NavUsers/NavUsers";
import NavDashboard from "../../ui/Navbar/NavItems/NavDashboard/NavDashboard";
import NavMarkets from "../../ui/Navbar/NavItems/NavMarkets/NavMarkets";
import React from "react";
import PropTypes from "prop-types";
import NavParcs from "../../ui/Navbar/NavItems/NavParcs/NavParcs";

/**
 *
 * Component General Description:
 * This is a HOC (High Order Component) use to return the
 * correspondit navbar items for a user role, in the CustomNavBar component.
 */
const withNavBarAuthenticated = (NavBarArgs, currentUser) => {
  return (
    <React.Fragment>
      {(currentUser.is_admin_arm || currentUser.is_administrateur) && (
        <NavBarArgs className="me-auto nav">
          <NavDashboard />
          <NavUsers />
          <NavProducts />
          <NavMarkets />
          <NavParcs />
        </NavBarArgs>
      )}
    </React.Fragment>
  );
};

withNavBarAuthenticated.propTypes = {
  /**
   * Description:
   * Connected user.
   */
  currentUser: PropTypes.object,

  /**
   * Description:
   * In our context, NavArgs is the 'Nav' element of 'react-bootstrap'
   * The HOC can be modify to use an other library.
   */
  NavBarArgs: PropTypes.element,
};

export default withNavBarAuthenticated;
