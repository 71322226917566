import { createTheme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const theme = createTheme();
const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "Poppins, sans-serif",
    width: "100%",
  },
  contentHead: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  contentBody: {
    width: "100%",
    height: "100%",
    marginTop: "40px",
    marginBottom: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  chart: {
    flex: 0.4,
    padding: "20px 0",
    display: "flex",
    justifyContent: "center",
  },
  chartLine: {
    flex: 0.8,
    width: "80%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
    justifyContent: "center",
    marginTop: "20px",
  },
  chartsGroup: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  map: {
    width: "85%",
    marginTop: "40px",
    height: "500px",
    boxShadow: "2px 2px 2px 2px gray",
  },
  stickerContainer: {
    display: "flex",
    gap: "50px",
    alignItems: "center",
    [theme.breakpoints.between("sm", "lg")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    },
  },
}));

export default useStyles;
