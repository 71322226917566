import { useFormik } from "formik";
import React from "react";
import LoginForm from "../../../components/Forms/LoginForm/LoginForm";
import loginValidationSchema from "../../../components/Forms/LoginForm/loginValidationSchema";
import { useAuthenticationContext } from "../../../utils/contexts/AuthenticationProvider";

function LoginFormContainer() {
  const { handleLogin } = useAuthenticationContext();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },

    validationSchema: loginValidationSchema,

    onSubmit: (values) => {
      handleLogin(values);
    },
  });
  return (
    <React.Fragment>
      <LoginForm formik={formik} />
    </React.Fragment>
  );
}

export default LoginFormContainer;
