import { FormControl, FormHelperText, NativeSelect } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import Items from "./Items/Items";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 150,
  },
}));

function SelectRegions({ items, formik }) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <FormControl className={classes.formControl}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <NativeSelect
            id="region"
            value={formik.values.region}
            onChange={formik.handleChange}
          >
            <Items items={items} compareInput={formik.values.region} />
          </NativeSelect>
          <FormHelperText error={formik.errors.region ? true : false}>
            {formik.errors.region}
          </FormHelperText>
        </div>
      </FormControl>
    </React.Fragment>
  );
}

export default SelectRegions;
