import React from "react";
import PropTypes from "prop-types";
/**
 * Component Description:
 * This component define the layout for the Connexion Container,
 * It need to be wrapped inside the container of the component.
 */

function LoginPageLayout({ children }) {
  return <React.Fragment>{children}</React.Fragment>;
}

LoginPageLayout.propTypes = {
  /**
   * Decription:
   * Node children.
   */
  children: PropTypes.node.isRequired,
};

export default LoginPageLayout;
